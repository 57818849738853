<template>
  <section class="py-20 px-6 text-white" id="service">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">What's Included</h3>
      <div class="mt-20 bg-white text-black p-10 rounded-xl leading-loose">
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/happy_news.svg')" width="100px" />
          <h4 class="text-2xl font-semibold underline mt-5">Action Plan Content & Automations</h4>
        </div>
         <p class="mt-5">This content includes reply-based emails, educational content, and incorporates your website content including the about page, sell pages, buy pages, blogs, and reviews online. A portion of the scripting for the emails is courtesy of the book <a href="https://www.ewts4re.com/" target="_blank" class="text-site-purple font-semibold underline">Exactly What To Say for Real Estate Agents by Phil M. Jones, Chris Smith, and Jimmy Mackin.</a> The initial text sent for new buyer leads &amp; new seller leads is customized by the source type &amp; the specific address inquiry if there is one in order to personalize it as much as possible.</p>
         <a class="inline-block text-site-purple font-semibold mt-5 cursor-pointer select-none" @click="showMoreActionPlans = !showMoreActionPlans">Read {{ showMoreActionPlans ? 'Less' : 'More' }} <font-awesome-icon :icon="`fa-solid fa-chevron-${showMoreActionPlans ? 'up' : 'down'}`" /></a>
         <div v-if="showMoreActionPlans" class="mt-5">
          <ul class="list-disc">
            <li><strong>New Buyer</strong> - 2 years of emails (Approx 29 emails)</li>
            <li><strong>New Zillow, Realtor, Trulia, Redfin</strong> - 2 years of emails (28 emails)</li>
            <li><strong>New Open House Lead</strong> - 2 years of emails (28 emails)</li>
            <li><strong>New Seller</strong> - 1 year of emails (Approx 17 emails)</li>
            <li><strong>Nurture Buyer</strong> - 3 years of emails (Approx 40 emails)</li>
            <li><strong>Nurture Seller</strong> - 3 years of emails (Approx 38 emails)</li>
            <li><strong>Post Closing</strong> - 11 years of emails (Approx 50 emails)</li>
            <li><strong>Back To Website</strong> - 6 months of emails (9 emails)</li>
            <li><strong>Stale</strong> - 12 months of emails (15 emails)</li>
            <li><strong>Homebot Engagements/Cloud CMA Lead/Automated Valuation Lead</strong> - 8 months of emails (14 emails - for homebot engagements, this is for Ylopo or osirs.co clients only.)</li>
            <li><strong>Sphere Nurture</strong> - 4 years of emails (Approx 21 emails)</li>
            <li><strong>Renter To Buyer Drip</strong> - 2.5 years of emails (27 emails) partial content courtesy of <a href="https://www.tooniceforsales.com/" class="text-site-purple font-semibold underline cursor-pointer" target="_blank">Barry Jenkins</a></li>
            <li><strong>New Rental Lead</strong> - 12 months of emails (18 emails)</li>
            <li><strong>Branded Website Action Plan</strong> - 6 months of emails (12 emails - Ylopo clients only)</li>
            <li><strong>Home Anniversary Action Plan</strong> - Spans to year 10</li>
            <li><strong>Pausing Action Plans Based On Stage Change</strong></li>
            <li><strong>Birthday</strong> - 20 years (1 email a year - Not yet available in Spanish)</li>
          </ul>
          <div class="bg-gray-200 p-10 mt-10 rounded-lg">
            <div class="text-center">
              <h1 class="text-3xl font-semibold">🎉 Revamp Extended 🎉</h1>
              <h2 class="text-xl">Optional Add-on - ${{ revampExtendedPricing }}</h2>
            </div>
            <p class="mt-5">Take it beyond our baseline offering with <strong>Revamp Extended</strong>. This add on includes the following action plans</p>
            <ul class="list-disc mt-5">
              <li><strong>Recruiting Drip</strong> - one year long (Approx 17 emails)</li>
              <li><strong>Nurture Expired Drip</strong> - two and half years long (Approx 24 emails) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
              <li><strong>Nurture FSBO Drip</strong> - two and a half years long (Approx 26 emails) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
              <li><strong>Luxury Nurture Buyer</strong> - 3 years of emails (Approx 40 emails)</li>
              <li><strong>Luxury Nurture Seller</strong> - just under 3 years of emails (Approx 36 emails)</li>
              <li><strong>Luxury Nurture Buyer + Seller</strong> - just under 6 years of emails (Approx 74 emails)</li>
              <li><strong>New Construction Lead</strong> - 27 months (approx 31 emails - Not yet available in Spanish)</li>
              <li><strong>Nurture New Construction</strong> - 30 months (approx 32 emails - Not yet available in Spanish)</li>
              <li><strong>Nurture Probate</strong> - 3 years of emails (approx. 31 emails - Not yet available in Spanish)</li>
              <li><strong>New Investor Buyer</strong> - 27 months (approx. 32 emails - Not yet available in Spanish)</li>
              <li><strong>Nurture Investor Buyer</strong> - 3 years (Approx. 40 emails - Not yet available in Spanish)</li>
              <li><strong>New Land Buyer Lead</strong> - 2 years of emails (approx. 29 emails) (Not yet available in Spanish)</li>
              <li><strong>Nurture Land Buyer Lead</strong> - 3 years of emails (approx. 40 emails) (Not yet available in Spanish)</li>
            </ul>
          </div>
          <div class="bg-gray-200 p-10 mt-10 rounded-lg">
            <div class="text-center">
              <h1 class="text-3xl font-semibold">🎉 Platform Pairing 🎉</h1>
              <h2 class="text-xl">Optional Add-ons - $100 per platform</h2>
            </div>
            <ul class="list-disc mt-5">
              <li><strong>Ylopo Users</strong> - Auto-Pausing Raiya With Accountability</li>
              <li><strong>Conversion Monster Users</strong> - Moving Leads To Stages Based On CM Tags + Applying Nurture Campaigns</li>
              <li v-if="fub"><strong>RealScout Users</strong> - 6 automations based on behavior tags</li>
              <li v-if="fub"><strong>Fello Users</strong> - 9 automations based on behavior tags</li>
            </ul>
          </div>
        </div>
        <hr>
        <div v-if="fub">
          <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/deals.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Deals Automations</h4>
          </div>
          <p class="mt-5">Automate your follow-up after a buyer or a seller becomes a signed client. These are email templates you will want to customize to ensure they are compliant with your brokerage and local regulations.</p>
          <a class="inline-block text-site-purple font-semibold mt-5 cursor-pointer select-none" @click="showMoreDealsAutomations = !showMoreDealsAutomations">Read {{ showMoreDealsAutomations ? 'Less' : 'More' }} <font-awesome-icon :icon="`fa-solid fa-chevron-${showMoreDealsAutomations ? 'up' : 'down'}`" /></a>
          <p v-if="showMoreDealsAutomations" class="mt-5">
            <ul class="list-disc">
              <li><strong>Active Buyer</strong> - Actively looking for a home - tips to stay motivated, encouraged, and stay sane.</li>
              <li><strong>Pending Buyer</strong> - Offer is accepted - what to expect next.</li>
              <li><strong>Pre-Listed Seller</strong> - Not active on market yet - how to prepare & what we will do for you.</li>
              <li><strong>Listed Seller</strong> - What to expect now that you're live on the market.</li>
              <li><strong>Pending Seller</strong> - What to expect now they've accepted an offer.</li>
            </ul>
          </p>
          <hr>
        </div>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/banking.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Lender Action Plans (optional add-on)</h4>
        </div>
        <p class="mt-5"><strong>PLEASE NOTE: We do not provide Spanish translations for our lender action plans at this time.</strong></p>
        <ul class="list-disc mt-5">
          <li><strong>new internet lead</strong> - 18 months of emails (Approx 24 emails)</li>
          <li><strong>new agent referral lead</strong> - 18 months of emails (Approx 24 emails)</li>
          <li><strong>nurture lead</strong> - 3 years of emails (Approx 40 emails)</li>
          <li><strong>credit repair nurture</strong> - 20 months of emails (Approx 22 emails)</li>
          <li><strong>stale lead</strong> - 1 year of emails (Approx 15 emails)</li>
          <li><strong>home anniversary</strong> - 10 years of emails (Approx 10 emails)</li>
          <li><strong>post closing</strong> - 5 years of emails (Approx 26 emails)</li>
        </ul>
        <p class="mt-5">If you are a lender looking for our lender specific revamp <router-link class="text-site-purple font-semibold underline cursor-pointer" to="/lender">click here</router-link> to learn more.</p>
        <hr>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/cleanup.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Clean Up and Set Up</h4>
        </div>
         <p class="mt-5">
          <ol class="list-decimal">
            <li>Provide new, simplified stages, clean up and merge existing stages, and define your new stages in your FUB account to help simplify your processes.</li>
            <li class="mt-5">Set up lead flows to ensure all new lead sources have the correct action plan assigned.</li>
          </ol>
        </p>
        <hr>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/lists.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Smart Lists</h4>
        </div>
         <p class="mt-5">There are a total of ten smart lists arranged by importance. These smart lists update automatically based on the filters set up. This will help you spend less time guessing who to call, pushing tasks forward, and focus on converting.</p>
        <p class="mt-5">If you are a Ylopo client or a Call Action client, additional smart lists will be added to ensure you don't miss out on important opportunities.</p>
        <hr>
        <div v-if="fub">
          <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/code.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Courtesy install of the Follow Up Boss Pixel</h4>
          </div>
          <p class="mt-5">As a courtesy to those who sign up for our FUB revamp service, we can attempt the installation of the Follow Up Boss Pixel on your website. With the Follow Up Boss Pixel installed on your website you can track lead activity and much more. With regards to our setup, the Follow Up Boss Pixel is the basis for smart lists and automations that pertain to website activity.</p>
          <p class="mt-5 text-sm font-medium">Please note that not every website is compatible with the Follow Up Boss Pixel therefore we cannot guarantee the success of the install.</p>
          <p class="mt-5 text-sm font-medium">Please note this offering is a courtesy service only and is not an objective step in the FUB revamp service we provide.</p>
          <hr>
        </div>
        <div class="flex flex-col items-center">
          <img :src="require('@/assets/images/training.svg')" width="120px" />
          <h4 class="text-2xl font-semibold underline mt-5">Training</h4>
        </div>
         <p class="mt-5 font-semibold">After the revamp is complete, we provide the following training:</p>
         <ul class="list-disc mt-5">
          <li>one-on-one personalized implementation call with a customer success manager</li>
          <li>Two seats (users) will act as ambassadors from your company and will receive 90 days access to interactive group training sessions. This totals over 40 hours of training available to you so you can have a full understanding of our setup and get all of your questions answered. These sessions will be recorded.</li>
          <li class="mt-2">Access to our video training library for in-depth training.</li>
          <li class="mt-2">A cheat sheet for quick reference on the revamp components. Our cheat sheet also lays out a clear plan for what to do daily in your FUB account.</li>
          <li class="mt-2">Access to bi-weekly calls with Elena and other past clients every other Tuesday at 11 am Central. These calls are interactive and you receive lifetime access.</li>
         </ul>
      </div>
    </div>
  </section>
</template>
<script>
import { SETUP_TYPE } from '../../../constants'
import { getPricing } from '../../../pricing'
export default {
  props: ['crm'],
  data() {
    return {
      showMoreActionPlans: false,
      showMoreDealsAutomations: false
    }
  },
  computed: {
    pricing() {
      return getPricing(SETUP_TYPE.FUB_AGENT_REVAMP)
    },
    fub() {
      return this.crm === 'Follow Up Boss'
    },
    revampExtendedPricing() {
      return this.pricing.REVAMP_EXTENDED.toLocaleString('en')
    }
  }
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-5;
}
</style>
