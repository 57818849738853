<template>
  <section class="py-20 px-6 text-white" id="pricing">
    <div class="container mx-auto">
      <h3 class="text-center text-4xl font-bold">Curious about pricing?</h3>
      <div class="mt-20 bg-gray-100 text-gray-700 p-10 lg:p-20 rounded-xl border-8 border-kts-gold">
        <p class="text-center text-3xl font-semibold">Base price for the revamp</p>
        <p class="text-center text-5xl mt-10 font-semibold">${{basePrice}} <span class="text-xl">USD</span></p>
        <div v-if="!payInFull">
          <p class="text-center mt-5"><span class="bg-site-purple text-white py-1 px-4 rounded-full">pay 50% deposit at sign up</span></p>
          <p class="text-center mt-5">pay 50% balance two weeks after the revamp is complete</p>
        </div>
        <p class="mt-5">* The project price does not include mass applying action plans to existing leads. The setup enables the new action plans to apply to new leads moving forward via lead flow or automations.</p>
        <div v-if="addOns">
          <hr />
          <p class="text-center text-3xl font-semibold mb-10">Take it to the<br>next level with add-ons</p>
          <div>
            <div v-for="addOn, idx in addOns" :key="`add_on_${idx}`" class="py-5 text-center bg-gray-200 mt-1">
              <p class="text-lg font-semibold">{{ addOn.title }} - ${{ addOn.price }}</p>
              <p>{{ addOn.subtext }}</p>
            </div>
          </div>
        </div>
        <hr />
        <p class="text-center text-2xl">Just some basic information is needed to get the ball rolling!</p>
        <div class="text-center mt-10">
          <a class="bg-kts-gold text-black uppercase font-semibold py-4 px-10 text-lg inline-block cursor-pointer tracking-wider hover-bright" @click="$emit('openSignUp')">Get Started</a>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['basePrice', 'addOns', 'payInFull']
}
</script>
<style scoped>
hr {
  @apply border-gray-300 my-14;
}
</style>