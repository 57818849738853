<template>
  <div>
    <section>
      <p class="font-semibold text-lg">Front Desk</p>
      <p class="text-sm">Basic Administrative Role, assist with incoming calls, provide overflow support to contract management and team leader/team manager, tidy office space, welcome clients to the office, call for feedback, track and facilitate client reviews/feedback, and overall an entry level admin position for larger teams, usually with their own office space.</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.frontDesk" placeholder="Front Desk" />
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Team Leader/Manager/DOO</p>
      <p class="text-sm">These tasks are the safety net to check in and ensure the tasks and smart lists are being followed. The leader who you would like to be alerted when an agent does not meet accountability standards when calling past clients, COI, and VIP COI. This role will be notified per new client plan and if accountability is needed as tasks are missed specifically by the sales agents, but also for ops and the team overall. Assign this to the team manager of Director of Operations once you have one. This can also be assigned to a strong admin role, otherwise for a growing team, the team leader would be assigned these tasks to oversee.</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.teamLeader" placeholder="Team Leader/Manager/DOO" />
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Contracts Management Partner</p>
      <p class="text-sm">Includes<br>Client Buyers or Seller Agreement to Closed<br>OR<br>Listing Co-ordinator: Listing to Firm Sale (Heavy Checklist Focus)<br>&<br>Transaction Co-ordinator: Firm Sale to Closed (Heavy Checklist Focus)<br>OR<br>Overall Admin/Ops if covering these aspects of the business as well</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.contractsMgm" placeholder="Contracts Management Partner" />
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Lead Pool</p>
      <p class="text-sm">Team pool where leads are directed if agent or ISA assigned do not meet minimum contact and frequency protocols. This is a pool where new recruits, and agents looking to pick up extra business, can call in order to establish a relationship, whereby the lead would then be assigned to the new agent with a follow-up smart list assigned.</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.leadPool" placeholder="Lead Pool" />
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Database Manager</p>
      <p class="text-sm">A role used by larger teams to oversee the CRM, lead assignment protocols, including nomenclature of the tagging systems, stages, and overall systems in the database. They monitor accountability, source of business accuracy, and bring any issues with missed or incomplete tasks directly to the agents or to a Team Leader, Director of Sales or Operations. Otherwise this would fall with the overall Operations or administrative role on a smaller team.</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.dbManager" placeholder="Database Manager" />
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Do you have a Lead Conversion Partner? LCP/ISA</p>
      <p class="text-sm">This is ISA, Inside Sales Agent, an agent who only calls and nurtures leads to book face to face consultations for sales agents on the team</p>
      <VRadio :v="v$.usesLeadConversionPartner" />
    </section>
    <section v-if="usesLeadConversionPartner === 'Yes'">
      <p class="font-semibold text-lg">Please Specify your Lead Conversion Partner</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.leadConversionPartner" placeholder="LCP/ISA" />
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Admin / Front Desk</p>
      <p class="text-sm">Create and Manage all Systems and oversee all administrative aspects of the agents day to day business.</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.admin" placeholder="Admin / Front Desk" />
      </div>
    </section>
  </div>
</template>
<script>
import pick from 'lodash/fp/pick'
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'

export default {
  mixins: [formValidationMixin],
  props: ["keeId"],
  data() {
    return {
      frontDesk: "",
      teamLeader: "",
      contractsMgm: "",
      leadPool: "",
      dbManager: "",
      usesLeadConversionPartner: "",
      leadConversionPartner: "",
      admin: ""
    };
  },
  validations() {
    return {
      frontDesk: { required },
      teamLeader: { required },
      contractsMgm: { required },
      leadPool: { required },
      dbManager: { required },
      usesLeadConversionPartner: { required },
      leadConversionPartner: { required: requiredIf(this.usesLeadConversionPartner === 'Yes') },
      admin: { required }
    };
  },
  methods: {
    getFields() {
      return pick([
        "frontDesk",
        "teamLeader",
        "contractsMgm",
        "leadPool",
        "dbManager",
        "usesLeadConversionPartner",
        "leadConversionPartner",
        "admin"
      ])(this)
    },
  },
};
</script>
<style scoped>
section {
  @apply mt-10;
}
</style>