<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
    <p class="text-lg mt-5">We will need the following information before we can start revamping your Sierra.</p>
    <!-- Sierra Credentials -->
    <section>
      <p class="font-semibold text-lg">Sierra Credentials</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.siteName" placeholder="Site Name" />
        <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
      </div>
    </section>
    <!-- Brokerage -->
    <section>
      <p class="font-semibold text-lg">Brokerage</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
      </div>
    </section>
    <!-- Business Address -->
    <section>
      <p class="font-semibold text-lg">Business Address</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.businessAddress.country"
          :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
        <StateSelect
          v-model="formModel.businessAddress.state"
          :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
          :showProvinces="formModel.businessAddress.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      </div>
    </section>
    <!-- Servicing Multiple Markets -->
    <section>
      <p class="font-semibold text-lg">Do you service more than one market (multiple states/provinces, regions)?</p>
      <VRadio :v="v$.formModel.multiMarketServicing" />
    </section>
    <!-- Online Reviews -->
    <section>
      <p class="font-semibold text-lg">Online Reviews <span class="text-base font-light text-gray-700">(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)</span></p>
      <!-- <p class="text-base font-light text-gray-700">This is so you can ask for reviews in the post closing drips and build trust with potential clients in the nuture drips</p> -->
      <div class="inline-block">
        <div class="field bg-gray-100 p-5" v-for="(onlineReview, idx) in formModel.onlineReviews"
          :key="`online_review_${idx}`" :class="{ 'mt-5': idx !== 0 }">
          <input type="text" v-model="onlineReview.url" placeholder="http://review-url.com" />
          <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)">
            <font-awesome-icon icon="fa-solid fa-times" />
          </span>
        </div>
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </section>
    <!-- YouTube Channel -->
    <section>
      <p class="font-semibold text-lg">YouTube Channel</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <div class="field">
          <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
        </div>
      </div>
    </section>
    <!-- Conversion Monster -->
    <section>
      <p class="font-semibold text-lg">Do you use Conversion Monster?</p>
      <VRadio :v="v$.formModel.usesConversionMonster" />
    </section>
    <!-- BombBomb -->
    <section>
      <p class="font-semibold text-lg">Do you have BombBomb videos?</p>
      <VRadio :v="v$.formModel.hasBombBombVideos" />
    </section>
    <section v-if="formModel.hasBombBombVideos === 'Yes'">
      <p class="font-semibold text-lg">BombBomb Credentials</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
      </div>
    </section>
    <!-- Ylopo Users -->
    <section>
      <p class="font-semibold text-lg">Are you a Ylopo user?</p>
      <VRadio :v="v$.formModel.ylopoUser" />
    </section>
    <!-- Ylopo Users - Yes - Search Site Live -->
    <div v-if="formModel.ylopoUser === 'Yes'">
      <section>
        <p class="font-semibold text-lg">Is your search site live?</p>
        <VRadio :v="v$.formModel.ylopoUserSearchSiteLive" />
      </section>
      <section>
        <p class="font-semibold text-lg">Is your branded website live?</p>
        <VRadio :v="v$.formModel.ylopoUserBrandedSiteLive" :options="['Yes', 'No', 'Not using']" />
      </section>
      <section v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'">
        <p class="font-semibold text-lg">Branded Website URL</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
        </div>
      </section>
    </div>
    <!-- Spanish Content -->
    <section>
      <p class="font-semibold text-lg">Include Spanish Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.SPANISH_ACTION_PLANS }})</span></p>
      <p class="text-sm font-semibold">PLEASE NOTE: We do not provide Spanish translations for our Canadian market material.</p>
      <p><a class="link underline text-base font-normal" @click="showSpanishContentModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> more info</a></p>
      <SpanishContentModal v-if="showSpanishContentModal" @close="showSpanishContentModal = false" />
      <VRadio :v="v$.formModel.spanishActionPlans" />
    </section>
    <section v-if="includeSpanishActionPlans">
      <p class="font-semibold text-lg">Default Language</p>
      <p class="text-sm">Used when language preference is unknown for a lead</p>
      <VRadio :v="v$.formModel.defaultLanguage" :options="['English', 'Spanish']" />
    </section>
    <!-- Lender Action Plans -->
    <section>
      <p class="font-semibold text-lg">Include Lender Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.LENDER_ADD_ON }})</span></p>
      <p class="text-sm font-semibold mt-2">PLEASE NOTE: We do not provide Spanish translations for lender action plans at this time.</p>
      <VRadio :v="v$.formModel.lenderAddOn" />
    </section>
    <section v-if="includeLenderAddOn">
      <p class="font-semibold text-lg">Do all lenders in your account work for the same mortgage company?</p>
      <VRadio :v="v$.formModel.singleMortgageCompany" />
    </section>
    <section v-if="formModel.singleMortgageCompany === 'Yes'">
      <p class="font-semibold text-lg">Lender Website</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <div class="field">
          <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
        </div>
      </div>
    </section>
    <section v-if="formModel.singleMortgageCompany === 'Yes'">
      <p class="font-semibold text-lg">Online Reviews for Lender <span class="text-base font-light text-gray-700">(Please provide
        any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)</span></p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <div class="field">
          <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
        </div>
      </div>
    </section>
    <!-- Setup activation notice -->
    <div class="p-10 mt-10 border bg-gray-100">
      <h3 class="text-lg font-semibold">Activating your new setup</h3>
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
      <p class="mt-5"><strong>Please note:</strong> Balance payment for the setup is due within two weeks from the setup completion date, irrespective of whether you choose immediate activation or a review period.</p>
    </div>
    <!-- Project Price -->
    <section class="inline-block border p-10 bg-gray-100 text-gray-700">
      <p class="text-xl font-medium">Project Price</p>
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
    </section>
    <!-- Deposit Payment -->
    <section>
      <div v-if="subscription" class="mt-5">
        <p class="text-2xl font-semibold">
          Payment Plan
        </p>
        <p>$100 per month until project price is paid in full</p>
        <p class="mt-2 text-sm">Due now is the first $100 installment payment. The remaining payments will auto charge exactly one month apart to the card provided below.</p>
      </div>
      <div v-else class="mt-5">
        <p class="text-2xl font-semibold">
          Deposit Payment
        </p>
        <p class="text-lg font-semibold mt-5">
          Deposit Amount: {{ depositDollarAmount }} <span class="text-sm font-normal">(USD)</span>
        </p>
        <p class="mt-2 text-sm">Due now is a deposit of half the total project price.</p>
        <p class="text-sm mt-2">A balance payment of
          {{ depositDollarAmount }} will be due within 2 weeks after the Sierra revamp is complete.<br /><strong>PLEASE
            NOTE:</strong> The two week window to pay balance <strong>IS NOT CONTINGENT</strong> on a follow up call
          with you after the setup. Two weeks is a hard deadline.</p>
      </div>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-5">
        <p class="font-semibold">Billing Information</p>
        <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
        <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.billing.country"
          :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.billing.city" placeholder="City" />
        <StateSelect
          v-model="formModel.billing.state"
          :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
          :showProvinces="formModel.billing.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
      </div>
    </section>
    <section>
      <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
        <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.agreeToConditions.$errors[0].$message }}
        </p>
        <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
        <label class="ml-2 font-medium">I agree to <a @click="showConditionAgreement = true" class="link">these conditions</a></label>
      </div>
    </section>
    <button @click="submit" :disabled="!canSubmit" class="button mt-5">{{ submitText }}</button>
    <p v-if="v$.$error" class="mt-2 text-red-500">
      Oops... There is an issue with the form. Check fields for errors.
    </p>
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :pricing="pricing"
      :pricingMeta="pricingMeta"
    />
    <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" type="sierra" />
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
      :receiptUrl="receiptUrl"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5" v-if="subscription">Your information has been submitted successfully and payment subscription has been set up.</p>
          <p class="text-xl pt-5" v-else>Your information has been submitted successfully and the deposit payment of <strong class="font-semibold">{{depositDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import { SETUP_TYPE } from '../../../constants';
import { getPricing } from '@/pricing'
import SpanishContentModal from "../setup-form/SpanishContentModal";

const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  components: { SpanishContentModal },
  props: ["keeId"],
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      showConditionAgreement: false,
      showPriceBreakdownModal: false,
      showSpanishContentModal: false,
      receiptUrl: "",
      formModel: {
        siteName: "",
        crmUsername: "",
        crmPassword: "",
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        spanishActionPlans: "",
        defaultLanguage: "",
        lenderAddOn: "",
        singleMortgageCompany: "",
        lenderWebsiteUrl: "",
        lenderOnlineReviews: "",
        multiMarketServicing: "",
        usesConversionMonster: "",
        hasBombBombVideos: "",
        bombbombUsername: "",
        bombbombPassword: "",
        ylopoUser: "",
        ylopoUserSearchSiteLive: "",
        ylopoUserBrandedSiteLive: "",
        ylopoUserBrandedSiteUrl: "",
        agreeToConditions: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      },
    };
  },
  validations() {
    const { usesBombBomb, ylopoUser, brandedSiteLive, includeLenderAddOn } = this
    
    return {
      formModel: {
        siteName: { required },
        crmUsername: { required },
        crmPassword: { required },
        youtube: {},
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        usesConversionMonster: { required },
        hasBombBombVideos: { required },
        bombbombUsername: { required: requiredIf(usesBombBomb) },
        bombbombPassword: { required: requiredIf(usesBombBomb) },
        ylopoUser: { required },
        ylopoUserSearchSiteLive: { required: requiredIf(ylopoUser) },
        ylopoUserBrandedSiteLive: { required: requiredIf(ylopoUser) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(brandedSiteLive) },
        agreeToConditions: { required },
        spanishActionPlans: { required },
        defaultLanguage: { required: requiredIf(this.includeSpanishActionPlans) },
        lenderAddOn: { required },
        singleMortgageCompany: { required: requiredIf(includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
        billing: {
          firstName: { required },
          lastName: { required },
          email: { required: requiredIf(this.subscription) },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.SIERRA
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.includeSpanishActionPlans) {
        amount += this.pricing.SPANISH_ACTION_PLANS
      }
      if (this.includeLenderAddOn) {
        amount += this.pricing.LENDER_ADD_ON
      }
      return amount
    },
    pricingMeta() {
      return {
        includeSpanishActionPlans: this.includeSpanishActionPlans,
        includeLenderAddOn: this.includeLenderAddOn,
      }
    },
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    subscription() {
      return this.$route.query.subscription === 'true'
    },
    depositAmount() {
      return this.totalAmount/2
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    depositDollarAmount() {
      return `$${this.depositAmount.toLocaleString('en')}`
    },
    includeSpanishActionPlans() {
      return this.formModel.spanishActionPlans === 'Yes'
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    usesBombBomb() {
      return this.formModel.hasBombBombVideos === "Yes";
    },
    ylopoUser() {
      return this.formModel.ylopoUser === "Yes";
    },
    brandedSiteLive() {
      return (
        this.ylopoUser && this.formModel.ylopoUserBrandedSiteLive === "Yes"
      );
    },
    nonYlopoUser() {
      return this.formModel.ylopoUser === "No";
    },
    includeLenderAddOn() {
      return this.formModel.lenderAddOn === 'Yes'
    },
    submitText() {
      return this.subscription ? 'Submit and pay first installment of $100' : `Submit and pay ${this.depositDollarAmount} Deposit`
    }
  },
  watch: {
    usesBombBomb(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.bombbombUsername = "";
        this.formModel.bombbombPassword = "";
      }
    },
    ylopoUser(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.ylopoUserSearchSiteLive = "";
        this.formModel.ylopoUserBrandedSiteLive = "";
        this.formModel.ylopoUserBrandedSiteUrl = "";
      }
    },
    brandedSiteLive(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.ylopoUserBrandedSiteUrl = "";
      }
    },
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    buildNotes() {
      return _pick(this.formModel, [
        'onlineReviews',
        'youtube',
        'brokerage',
        'businessAddress',
        'multiMarketServicing',
        'usesConversionMonster',
        'hasBombBombVideos',
        'bombbombUsername',
        'bombbombPassword',
        'ylopoUser',
        'ylopoUserSearchSiteLive',
        'ylopoUserBrandedSiteLive',
        'ylopoUserBrandedSiteUrl',
        'spanishActionPlans',
        'defaultLanguage',
        'lenderAddOn',
        'singleMortgageCompany',
        'lenderWebsiteUrl',
        'lenderOnlineReviews',
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes();
        const billing = this.formModel.billing;
        const businessAddress = this.formModel.businessAddress
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize();
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          basicInfo: null,
          keeId: this.keeId,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          meta: {
            setupType: this.setupType,
            subscription: this.subscription,
            spanishActionPlans: this.includeSpanishActionPlans,
            includeLenderAddOn: this.includeLenderAddOn,
            legacyPricing: this.legacyPricing,
            sierraSiteName: this.formModel.siteName
          },
          payment: {
            note: 'Sierra Deposit Payment',
            isDeposit: true,
            billing,
            locationId,
            sourceId,
          },
          businessAddress
        })

        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
