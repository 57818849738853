<template>
  <Modal @close="$emit('close')">
    <div class="text-center">
      <h1 class="text-3xl font-semibold">🎉 Revamp Extended 🎉</h1>
      <h2 class="text-xl">Optional Add-on - ${{ price }}</h2>
    </div>
    <p class="mt-5">Take it beyond our basline offering with <strong>Revamp Extended</strong>. This add on includes the following action plans</p>
    <ul class="list-disc mt-5">
      <li><strong>Recruiting Drip</strong> - one year long (Approx 17 emails)</li>
      <li><strong>Nurture Expired Drip</strong> - two and half years long (Approx 24 emails) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
      <li><strong>Nurture FSBO Drip</strong> - two and a half years long (Approx 26 emails) (not intended for cold lead reach out. MUST have made a connection with lead)</li>
      <li><strong>Luxury Nurture Buyer</strong> - 3 years of emails (Approx 40 emails)</li>
      <li><strong>Luxury Nurture Seller</strong> - just under 3 years of emails (Approx 36 emails)</li>
      <li><strong>Luxury Nurture Buyer + Seller</strong> - just under 6 years of emails (Approx 74 emails)</li>
      <li><strong>New Construction Lead</strong> - 27 months (approx 31 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture New Construction</strong> - 30 months (approx 32 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture Probate</strong> - 3 years of emails (approx. 31 emails - Not yet available in Spanish)</li>
      <li><strong>New Investor Buyer</strong> - 27 months (approx. 32 emails - Not yet available in Spanish)</li>
      <li><strong>Nurture Investor Buyer</strong> - 3 years (Approx. 40 emails - Not yet available in Spanish)</li>
      <li><strong>New Land Buyer Lead</strong> - 2 years of emails (approx. 29 emails) (Not yet available in Spanish)</li>
      <li><strong>Nurture Land Buyer Lead</strong> - 3 years of emails (approx. 40 emails) (Not yet available in Spanish)</li>
    </ul>
  </Modal>
</template>
<script>
export default {
  props: ['price']
}
</script>