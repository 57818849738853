<template>
  <section class="py-20 px-6 bg-site-gray text-white" id="benefits">
    <div class="container mx-auto">
      <div class="flex justify-center">
        <div class="bg-kts-gold text-black py-1 px-4 rounded-full font-medium">Benefits</div>
      </div>
      <p class="text-center text-4xl mt-5 font-bold">Our done-for-you setup will...</p>
      <div v-for="benefit, idx in benefits" :key="`benefit_${idx}`" class="p-10 flex items-center" :class="{ 'border-t border-kts-gold': idx > 0 }">
        <div class="flex-grow">
          <div class="text-kts-gold text-5xl font-bold">{{ idx + 1 }}</div>
          <h3 class="mt-4 text-3xl font-semibold">{{ benefit.title }}</h3>
          <p class="mt-4 text-lg">{{ benefit.body }}</p>
        </div>
        <div class="w-44 ml-14 flex-shrink-0 hidden md:block">
          <img :src="require(`@/assets/images/${benefit.image}`)" />
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  computed: {
    benefits() {
      return [
        {
          title: "Start meaningful conversations",
          body: "Our action plans include email content that is empathetic, personable, human, and generates responses.",
          image: "conversations.svg"
        },
        {
          title: "Provide you a realistic workflow",
          body: "Reduce your tasks and the noise by using our simplified structure for your CRM",
          image: "workflow.svg"
        },
        {
          title: "Ensure no lead gets lost in your system",
          body: "Our automations allow you to quickly ensure leads are in the right smart lists for your daily calling and on the right action plan to supplement your efforts and provide value to your leads and clients.",
          image: "search.svg"
        }
      ]
    }
  }
}
</script>