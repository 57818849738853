<template>
  <section class="py-20 px-6" id="hero">
    <div class="container max-w-4xl mx-auto text-white">
      <div v-if="pillText" class="flex justify-center mb-5"><p  class="bg-site-purple text-center text-lg text-white py-1 px-4 rounded-full font-semibold">{{ pillText }}</p></div>
      <h1 class="text-4xl sm:text-5xl md:text-7xl text-center font-semibold" style="line-height: 1.1;" v-html="headingHtml"></h1>
      <h2 class="text-lg sm:text-xl md:text-2xl text-center mt-10">{{ secondHeading }}</h2>
      <div class="flex flex-col sm:flex-row justify-center items-center mt-10">
        <a class="bg-kts-gold text-black uppercase font-semibold py-4 px-10 text-lg sm:text-xl tracking-wider cursor-pointer hover-bright w-64 text-center" :href="calendarUrl" target="_blank">Book a Call</a>
        <a class="text-kts-gold border border-kts-gold uppercase font-semibold py-4 px-10 sm:ml-5 tracking-wider text-lg sm:text-xl cursor-pointer hover-bright w-64 text-center mt-5 sm:mt-0" @click="openDemo">Watch Demo</a>
      </div>
      <div class="flex justify-center mt-10">
        <div class="-mt-2">
          <p v-for="check, idx in checks" :key="`check_${idx}`" class="mt-2 sm:text-lg">✅ {{ check }}</p>
        </div>
      </div>
      <p v-if="!nonFollowUpBoss" class="text-center mt-10">Not a Follow Up Boss user? <a class="text-kts-gold cursor-pointer underline" href="https://www.followupboss.com/" target="_blank">Click here</a> to learn more.</p>
    </div>
    <SignUpForm v-if="showSignUp" @close="showSignUp = false" :setupType="setupType" :affiliate="affiliate" />
    <Demo v-if="showDemo" @close="showDemo = false" :videoUrl="demoVideoUrl" />
  </section>
</template>
<script>
import SignUpForm from './SignUpForm.vue'
import Demo from './Demo.vue'
import { SETUP_TYPE, DEMO_VIDEO_ID } from '../../../constants'

export default {
  props: ['setupType', 'affiliate', 'calendarUrl'],
  components: {
    SignUpForm,
    Demo
  },
  data() {
    return {
      showSignUp: false,
      showDemo: false
    };
  },
  computed: {
    headingHtml() {
      switch (this.setupType) {
        case SETUP_TYPE.BOSS_ASSIST: return `We train your<br><span class="font-extrabold">Virtual Assistant</span><br>so you can <span class="font-extrabold hero-em">SCALE</span>`
        case SETUP_TYPE.TC_ASSIST: return `Power up your<br><span class="font-extrabold">Follow Up Boss</span><br>for <span class="font-extrabold hero-em">Success</span>`
        case SETUP_TYPE.SIERRA: return `Revamp your<br><span class="font-extrabold">Sierra</span><br>for <span class="font-extrabold hero-em">Success</span>`
        default: return `Revamp your<br><span class="font-extrabold">Follow Up Boss</span><br>for <span class="font-extrabold hero-em">Success</span>`
      }
    },
    secondHeading() {
      switch (this.setupType) {
        case SETUP_TYPE.BOSS_ASSIST: return "BossAssist by Kee Technology Solutions trains virtual assistants on Follow Up Boss to become database managers so that you can grow your business."
        case SETUP_TYPE.TC_ASSIST: return "TC Assist by Kee Technology Solutions helps solo agents and teams automate, simplify and streamline their CRM."
        case SETUP_TYPE.FUB_LENDER_REVAMP: return "Kee Technology Solutions helps lenders automate, simplify and streamline their CRM."
        default: return "Kee Technology Solutions helps solo agents, teams, and brokerages automate, simplify and streamline their CRM."
      }
    },
    checks() {
      switch (this.setupType) {
        case SETUP_TYPE.BOSS_ASSIST: return [
          'Online course & quizzes',
          'Live training',
          'Standard Operating Procedures'
        ]
        case SETUP_TYPE.TC_ASSIST: return [
          'Action Plans with proven results',
          'Automated Transaction Managemnt System',
          'Best practices for a realistic and efficient workflow'
        ]
        default: return [
          'Action Plans with proven results',
          'Automated System',
          'Best practices for a realistic workflow'
        ]
      }
    },
    pillText() {
      switch (this.setupType) {
        case SETUP_TYPE.BOSS_ASSIST: return 'BossAssist'
        case SETUP_TYPE.TC_ASSIST: return 'TC Assist - Transaction Coordination!'
        case SETUP_TYPE.FUB_LENDER_REVAMP: return 'Lender Revamp'
        default: return ''
      }
    },
    demoVideoUrl() {
      return `https://www.youtube.com/embed/${DEMO_VIDEO_ID[this.setupType]}?autoplay=1`
    },
    isTransaction() {
      return this.setupType === SETUP_TYPE.TC_ASSIST
    },
    nonFollowUpBoss() {
      return this.setupType === SETUP_TYPE.SIERRA
    }
  },
  methods: {
    openSignUp() {
      this.showSignUp = true
    },
    openDemo() {
      this.showDemo = true
    },
  },
}
</script>