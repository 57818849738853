<template>
  <FormPageLayout>
    <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
    <p class="text-lg mt-5">We will need the following information before we can start revamping your FUB.</p>
    <!-- Basic Info -->
    <section v-if="!keeId">
      <p class="font-semibold text-lg">Your Info</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name"/>
        <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name"/>
        <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email"/>
        <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number"/>
      </div>
    </section>
    <!-- Setup Enabled For -->
    <section>
      <p class="font-semibold text-lg">Who will the setup be enabled for?</p>
      <div class="field" :class="{'field-block-error': v$.formModel.setupEnabledFor.$error}">
        <p v-if="v$.formModel.setupEnabledFor.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.setupEnabledFor.$errors[0].$message }}
        </p>
        <input type="radio" value="All users" v-model="formModel.setupEnabledFor" />
        <label>All users within the FUB account <span class="text-gray-600 text-sm">(This option is the default behavior of our setup and is recommended for most teams and solo agents.)</span></label>
        <br />
        <input type="radio" value="Only me or a limited set of users" v-model="formModel.setupEnabledFor" />
        <label>Only me OR a limited set of users in the FUB account <span class="text-gray-600 text-sm">(This option is a customization to our default setup and is an additional ${{ customUserEnablingPricing }} for the custom work. Any changes to this after the setup is complete will be billed as hourly work.)</span></label>
      </div>
    </section>
    <!-- Follow Up Boss Credentials -->
    <section>
      <p class="font-semibold text-lg">
        Follow Up Boss Credentials
        <span class="text-base font-light text-gray-700">(Owner Account)</span>
      </p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
      </div>
    </section>
    <!-- Kathleen Black -->
    <KathleenBlack v-if="isKBC" ref="kathleen" />
    <!-- Website URL(s) -->
    <section class="inline-block">
      <p class="font-semibold text-lg -mb-2">Website URL</p>
      <div class="field bg-gray-100 p-5" v-for="(website, idx) in formModel.websites" :key="`website_${idx}`"
        :class="{ 'mt-5': idx !== 0 }">
        <input type="text" v-model="website.url" placeholder="http://mywebsite.com" />
        <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeWebsite(idx)">
          <font-awesome-icon icon="fa-solid fa-times" />
        </span>
      </div>
      <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
      <a @click="addWebsite" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Website
      </a>
    </section>
    <!-- Online Reviews -->
    <section>
      <p class="font-semibold text-lg">Online Reviews <span class="text-base font-light text-gray-700">(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)</span></p>
      <div class="inline-block">
        <div class="field bg-gray-100 p-5" v-for="(onlineReview, idx) in formModel.onlineReviews" :key="`online_review_${idx}`" :class="{ 'mt-5': idx !== 0 }">
          <input type="text" v-model="onlineReview.url" placeholder="http://review-url.com" />
          <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)">
            <font-awesome-icon icon="fa-solid fa-times" />
          </span>
        </div>
      </div>
      <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
      <a @click="addOnlineReview" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Online Review URL
      </a>
    </section>
    <!-- Brokerage -->
    <section>
      <p class="font-semibold text-lg">Brokerage</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
      </div>
    </section>
    <!-- Business Address -->
    <section>
      <p class="font-semibold text-lg">Business Address</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.businessAddress.country"
          :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
        <StateSelect
          v-model="formModel.businessAddress.state"
          :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
          :showProvinces="formModel.businessAddress.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      </div>
    </section>
    <!-- Servicing Multiple Markets -->
    <section>
      <p class="font-semibold text-lg">Do you service more than one market (multiple states/provinces, regions)?</p>
      <VRadio :v="v$.formModel.multiMarketServicing" />
    </section>
    <!-- YouTube Channel -->
    <section>
      <p class="font-semibold text-lg">YouTube Channel</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <div class="field">
          <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
        </div>
      </div>
    </section>
    <!-- BombBomb -->
    <section>
      <p class="font-semibold text-lg">Do you have BombBomb videos?</p>
      <VRadio :v="v$.formModel.hasBombBombVideos" />
    </section>
    <section v-if="formModel.hasBombBombVideos === 'Yes'">
      <p class="font-semibold text-lg">BombBomb Credentials</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
        <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
      </div>
    </section>
    <!-- RealScout -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">RealScout - Platform Pairing Add-On</p>
      <p class="font-semibold text-lg">Include RealScout Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.REAL_SCOUT.toLocaleString('en') }})</span></p>
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using RealScout</p>
      <ToggleSwitch v-model="formModel.includeRealScoutActionPlans" />
    </section>
    <!-- Fello -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">Fello - Platform Pairing Add-On</p>
      <p class="font-semibold text-lg">Include Fello Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.FELLO.toLocaleString('en') }})</span></p>
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Fello</p>
      <ToggleSwitch v-model="formModel.includeFelloActionPlans" />
    </section>
    <!-- Conversion Monster -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">Conversion Monster - Platform Pairing Add-On</p>
      <p class="font-semibold text-lg">Include Conversion Monster Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.CONVERSION_MONSTER.toLocaleString('en') }})</span></p>
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Conversion Monster</p>
      <ToggleSwitch v-model="formModel.includeConversionMonsterActionPlans" />
    </section>
    <!-- Ylopo Users -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">Ylopo - Platform Pairing Add-On</p>
      <p class="font-semibold text-lg">Include Ylopo Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.YLOPO.toLocaleString('en') }})</span></p>
      <p class="text-sm font-medium">Note: By making this selection you agree that you are currently using Ylopo</p>
      <ToggleSwitch v-model="formModel.includeYlopoActionPlans" />
    </section>
    <!-- Ylopo Users - Yes - Search Site Live -->
    <div v-if="formModel.includeYlopoActionPlans">
      <section>
        <p class="font-semibold text-lg">Is your search site live?</p>
        <VRadio :v="v$.formModel.ylopoUserSearchSiteLive" />
      </section>
      <section>
        <p class="font-semibold text-lg">Is your branded website live?</p>
        <VRadio :v="v$.formModel.ylopoUserBrandedSiteLive" :options="['Yes', 'No', 'Not using']" />
      </section>
      <section v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'">
        <p class="font-semibold text-lg">Branded Website URL</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
        </div>
      </section>
    </div>
    <section>
      <p class="font-semibold text-lg">Is the Follow Up Boss Pixel installed on your website?</p>
      <VRadio :v="v$.formModel.fubPixelInstalled" :options="['Yes', 'No', 'Not compatible']" />
    </section>
    <section v-if="formModel.fubPixelInstalled === 'No' && !formModel.includeYlopoActionPlans" class="inline-block">
      <p class="font-semibold text-lg">Courtesy service for installing the Follow Up Boss Pixel</p>
      <p class="text-sm text-gray-700">If you have a website backend and would like us to attempt the installation of the Follow Up Boss pixel for you, please provide your site's credentials below.<br><br><strong>Please note</strong> we offer this install service as a courtesy only and is not an objective step in the FUB revamp service we provide.<br><br><strong>Please note</strong> not every website is compatible with the Follow Up Boss Pixel and some site providers even reject the install of 3rd party scripts. Often we don't know compatibility issues until we attempt to install.<br><br>If you prefer to install the FUB pixel yourself you can refer to <a class="link" href="https://help.followupboss.com/hc/en-us/sections/1500001949321-Follow-Up-Boss-Pixel" target="_blank">Follow Up Boss Pixel documentation</a></p>
      <div v-for="(websiteBackend, idx) in formModel.websiteBackends" :key="`website_backend_${idx}`"
        class="bg-gray-100 p-5 pt-3 mt-5">
        <div v-if="idx !== 0" class="flex justify-end">
          <span class="cursor-pointer" @click="removeBackend(idx)">
            <font-awesome-icon icon="fa-solid fa-times" /> Remove
          </span>
        </div>
        <div class="field">
          <input type="text" v-model="websiteBackend.url" placeholder="Backend URL" />
        </div>
        <div class="field">
          <input type="text" v-model="websiteBackend.username" placeholder="Username" />
        </div>
        <div class="field">
          <input type="text" v-model="websiteBackend.password" placeholder="Password" />
        </div>
      </div>
      <p class="mt-5 font-semibold text-sm">
        Need the FUB pixel added to multiple websites?
      </p>
      <a @click="addBackend" class="
        text-site-purple
        font-semibold
        mt-1
        inline-block
        underline
        cursor-pointer
      ">
        <font-awesome-icon icon="fa-solid fa-plus-circle" />
        Add Website
      </a>
    </section>
    <!-- Stage setup -->
    <section>
      <p class="font-semibold text-lg">Setting up stages for the revamp<br /><span class="link underline text-base font-normal" @click="showStagesModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see stage definitions</span></p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
        <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.stageSetup.$errors[0].$message }}
        </p>
        <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
        <label>Use the default stages provided by the revamp (recommended for most)</label>
        <br />
        <input type="radio" :value="stageSetupTypes.ZILLOW_TWO_WAY_SYNC" v-model="formModel.stageSetup" />
        <label>I want to use the Zillow Stages via the 2-way sync with Zillow - more info <a class ="link" href="https://help.followupboss.com/hc/en-us/articles/8895957403927" target="_blank">here</a></label>
        <br />
        <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
        <label>I would like to opt out of using the default stages used by the revamp. Instead, I have a requirement that the revamp be customized to use predefined stages that I will provide to Kee Technology Solutions. I acknowledge that I must provide names of my stages before work on the revamp can be started and <strong>I agree to pay a ${{ customStagesPricing }} customization fee.</strong> I acknowledge that my review is required before going live with the revamp. (Note: We will reach out to you after your submission to obtain a list of stages we can use. We will reach back out after the setup is complete to review the setup with you before we go live with it.)</label>
      </div>
      <StagesModal v-if="showStagesModal" @close="showStagesModal = false" />
    </section>
    <!-- SMS Add On -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">SMS Add-On</p>
      <p class="font-semibold text-lg">Include add-on SMS Drips? <span class="text-base font-normal">(Additional ${{ pricing.SMS_DRIPS }})</span></p>
      <p class="mt-2">If you currently use either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong> for automated texting in FUB, we can add our SMS Drips to the setup we provide for an additional ${{ pricing.SMS_DRIPS }}.</p>
      <p class="mt-2 text-sm font-medium">Note: You can always sign up for this add-on at a later time if, in the future, you sign up for either Texting Betty, Leadngage or StreetText.</p>
      <p class="mt-2 text-sm font-medium">By making this selection you agree that you <strong>currently</strong> are using either <strong>Texting Betty</strong>, <strong>Leadngage</strong>, or <strong>StreetText</strong>.</p>
      <ToggleSwitch v-model="formModel.includeSMSDrips" />
    </section>
    <section v-if="formModel.includeSMSDrips">
      <p class="font-semibold text-lg">Texting automation service you use</p>
      <VRadio :v="v$.formModel.textingService" :options="['Texting Betty', 'Leadngage', 'StreetText']" />
    </section>
    <!-- Revamp Extended Add-on -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">Revamp Extended Add-On</p>
      <p class="font-semibold text-lg">Include add-on "Revamp Extended"? <span class="text-base font-normal">(Additional ${{ pricing.REVAMP_EXTENDED.toLocaleString('en') }})</span></p>
      <p class="link underline text-base font-normal" @click="showRevampExtendedModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see what's included</p>
      <ToggleSwitch v-model="formModel.includeRevampExtended" />
      <RevampExtendedModal v-if="showRevampExtendedModal" @close="showRevampExtendedModal = false" :price="pricing.REVAMP_EXTENDED.toLocaleString('en')" />
    </section>
    <!-- Spanish Content -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">Spanish Add-On</p>
      <p class="font-semibold text-lg">Include add-on Spanish Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.SPANISH_ACTION_PLANS }})</span></p>
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for our Canadian market material.</p>
      <p class="text-sm font-medium">Note: After the setup is complete, your review will be required before we can go live with it.</p>
      <p><a class="link underline text-base font-normal" @click="showSpanishContentModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> more info</a></p>
      <SpanishContentModal v-if="showSpanishContentModal" @close="showSpanishContentModal = false" />
      <ToggleSwitch v-model="formModel.includeSpanishActionPlans" />
    </section>
    <section v-if="formModel.includeSpanishActionPlans">
      <p class="font-semibold text-lg">Default Language</p>
      <p class="text-sm">Used when language preference is unknown for a lead</p>
      <VRadio :v="v$.formModel.defaultLanguage" :options="['English', 'Spanish']" />
    </section>
    <!-- Lender Action Plans -->
    <section>
      <p class="bg-site-purple text-white inline-block py-1 px-3 rounded-full">Lender Add-On</p>
      <p class="font-semibold text-lg">Include add-on Lender Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.LENDER_ADD_ON }})</span></p>
      <p class="text-sm font-medium">Note: We do not provide Spanish translations for lender action plans at this time.</p>
      <p class="text-sm font-medium">Note: By making this selection you agree to the terms that the lenders in your Follow Up Boss account are in fact "Lender" type users. The lender action plans will not work otherwise.</p>
      <ToggleSwitch v-model="formModel.includeLenderAddOn" />
    </section>
    <section v-if="formModel.includeLenderAddOn">
      <p class="font-semibold text-lg">Do all lenders in your account work for the same mortgage company?</p>
      <VRadio :v="v$.formModel.singleMortgageCompany" />
    </section>
    <section v-if="formModel.singleMortgageCompany === 'Yes'">
      <p class="font-semibold text-lg">Lender Website</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <div class="field">
          <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
        </div>
      </div>
    </section>
    <section v-if="formModel.singleMortgageCompany === 'Yes'">
      <p class="font-semibold text-lg">Online Reviews for Lender <span class="text-base font-light text-gray-700">(Please provide
        any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)</span></p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <div class="field">
          <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
        </div>
      </div>
    </section>
    <!-- Setup activation notice -->
    <div class="p-10 mt-10 border bg-gray-100">
      <h3 class="text-lg font-semibold">Activating your new setup</h3>
      <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
      <p class="mt-5"><strong>Please note:</strong> Balance payment for the setup is due within two weeks from the setup completion date, irrespective of whether you choose immediate activation or a review period.</p>
    </div>
    <!-- Project Price -->
    <section class="inline-block border p-10 bg-gray-100 text-gray-700">
      <p class="text-xl font-medium">Project Price</p>
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
    </section>
    <!-- Deposit Payment -->
    <section>
      <div v-if="subscription" class="mt-5">
        <p class="text-2xl font-semibold">
          Payment Plan
        </p>
        <p>$100 per month until project price is paid in full</p>
        <p class="mt-2 text-sm">Due now is the first $100 installment payment. The remaining payments will auto charge exactly one month apart to the card provided below.</p>
      </div>
      <div v-else class="mt-5">
        <p class="text-2xl font-semibold">
          Deposit Payment
        </p>
        <p class="text-lg font-semibold mt-5">
          Deposit Amount: {{ depositDollarAmount }} <span class="text-sm font-normal">(USD)</span>
        </p>
        <p class="mt-2 text-sm">Due now is a deposit of half the total project price.</p>
        <p class="text-sm mt-2">A balance payment of
          {{ depositDollarAmount }} will be due within 2 weeks after the FUB revamp is complete.<br /><strong>PLEASE
            NOTE:</strong> The two week window to pay balance <strong>IS NOT CONTINGENT</strong> on a follow up call
          with you after the setup. Two weeks is a hard deadline.</p>
      </div>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-5">
        <p class="font-semibold">Billing Information</p>
        <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
        <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.billing.country"
          :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.billing.city" placeholder="City" />
        <StateSelect
          v-model="formModel.billing.state"
          :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
          :showProvinces="formModel.billing.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
      </div>
    </section>
    <section>
      <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
        <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.agreeToConditions.$errors[0].$message }}
        </p>
        <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
        <label class="ml-2 font-medium">I agree to
          <a @click="showConditionAgreement = true" class="link">these conditions</a></label>
      </div>
    </section>
    <button @click="submit" :disabled="!canSubmit" class="button mt-5">{{ submitText }}</button>
    <p v-if="v$.$error" class="mt-2 text-red-500">
      Oops... There is an issue with the form. Check fields for errors.
    </p>
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :pricing="pricing"
      :pricingMeta="pricingMeta"
    />
    <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" />
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :showNextSteps="true"
      :receiptUrl="receiptUrl"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5" v-if="subscription">Your information has been submitted successfully and payment subscription has been set up.</p>
          <p class="text-xl pt-5" v-else>Your information has been submitted successfully and the deposit payment of <strong class="font-semibold">{{depositDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
      <template #promo v-if="!formModel.includeSMSDrips">
        <hr class="my-10" />
        <p class="text-lg font-medium">💬 Looking for a texting drip solution?</p>
        <p class="mt-3">We have a partnership solution with each of the texting services linked below. Click the links to learn more about each solution.</p>
        <ul class="mt-5 text-lg font-semibold">
          <li>
            <a class="link" @click="openRouteInNewWindow('texting-betty')">Texting Betty</a>
          </li>
          <li class="mt-5">
            <a class="link" @click="openRouteInNewWindow('leadngage')">Leadngage</a>
          </li>
          <li class="mt-5">
            <a class="link" @click="openRouteInNewWindow('streettext')">StreetText</a>
          </li>
          <li class="mt-5">
            <a class="link" @click="openRouteInNewWindow('kts-ai')">KTS AI w/ Structurely </a>
          </li>
        </ul>
        <hr class="mt-10" />
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import StagesModal from './StagesModal'
import SpanishContentModal from "./SpanishContentModal";
import RevampExtendedModal from './RevampExtendedModal.vue'
import { STAGE_SETUP_TYPES, SETUP_TYPE } from '../../../constants';
import { getPricing } from '@/pricing'
import KathleenBlack from './KathleenBlack'

const newWebsiteBackend = {
  url: "",
  username: "",
  password: "",
};
const newWebsite = {
  url: "",
};
const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  components: { StagesModal, SpanishContentModal, KathleenBlack, RevampExtendedModal },
  props: ["keeId", "setupType"],
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      showConditionAgreement: false,
      showStagesModal: false,
      showSpanishContentModal: false,
      showPriceBreakdownModal: false,
      showRevampExtendedModal: false,
      receiptUrl: "",
      formModel: {
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        setupEnabledFor: "",
        crmUsername: "",
        crmPassword: "",
        websites: [{ ...newWebsite }],
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        includeSMSDrips: false,
        textingService: "",
        includeLenderAddOn: false,
        singleMortgageCompany: "",
        lenderWebsiteUrl: "",
        lenderOnlineReviews: "",
        multiMarketServicing: "",
        includeRealScoutActionPlans: false,
        includeFelloActionPlans: false,
        includeConversionMonsterActionPlans: false,
        hasBombBombVideos: "",
        bombbombUsername: "",
        bombbombPassword: "",
        includeYlopoActionPlans: false,
        ylopoUserSearchSiteLive: "",
        ylopoUserBrandedSiteLive: "",
        ylopoUserBrandedSiteUrl: "",
        fubPixelInstalled: "",
        websiteBackends: [{ ...newWebsiteBackend }],
        includeRevampExtended: false,
        includeSpanishActionPlans: false,
        defaultLanguage: "",
        stageSetup: "",
        agreeToConditions: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      },
    };
  },
  validations() {
    const requiredIfNoKeeId = { required: requiredIf(!this.keeId) }
    
    return {
      formModel: {
        basicInfo: {
          firstName: requiredIfNoKeeId,
          lastName: requiredIfNoKeeId,
          email: requiredIfNoKeeId,
          phone: requiredIfNoKeeId
        },
        setupEnabledFor: { required },
        crmUsername: { required },
        crmPassword: { required },
        youtube: {},
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        textingService: { required: requiredIf(this.formModel.includeSMSDrips) },
        hasBombBombVideos: { required },
        bombbombUsername: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        bombbombPassword: { required: requiredIf(this.formModel.hasBombBombVideos === "Yes") },
        ylopoUserSearchSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteLive: { required: requiredIf(this.formModel.includeYlopoActionPlans) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(this.formModel.ylopoUserBrandedSiteLive === "Yes") },
        fubPixelInstalled: { required },
        stageSetup: { required },
        defaultLanguage: { required: requiredIf(this.formModel.includeSpanishActionPlans) },
        agreeToConditions: { required },
        singleMortgageCompany: { required: requiredIf(this.formModel.includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
        billing: {
          firstName: { required },
          lastName: { required },
          email: { required: requiredIf(this.subscription) },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.customUserEnabling) {
        amount += this.customUserEnablingPricing
      }
      if (this.customizeStages) {
        amount += this.customStagesPricing
      }
      if (this.formModel.includeSMSDrips) {
        amount += this.pricing.SMS_DRIPS
        if (this.customizeStages) {
          amount += this.pricing.SMS_DRIPS_CUSTOM_STAGES
        }
      }
      if (this.formModel.includeRevampExtended) {
        amount += this.pricing.REVAMP_EXTENDED
      }
      if (this.formModel.includeSpanishActionPlans) {
        amount += this.pricing.SPANISH_ACTION_PLANS
      }
      if (this.formModel.includeLenderAddOn) {
        amount += this.pricing.LENDER_ADD_ON
      }
      if (this.formModel.includeFelloActionPlans) {
        amount += this.pricing.FELLO
      }
      if (this.formModel.includeConversionMonsterActionPlans) {
        amount += this.pricing.CONVERSION_MONSTER
      }
      if (this.formModel.includeRealScoutActionPlans) {
        amount += this.pricing.REAL_SCOUT
      }
      if (this.formModel.includeYlopoActionPlans) {
        amount += this.pricing.YLOPO
      }
      return amount
    },
    pricingMeta() {
      return {
        customizeStages: this.customizeStages,
        customUserEnabling: this.customUserEnabling,
        includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
        includeLenderAddOn: this.formModel.includeLenderAddOn,
        includeSMSDrips: this.formModel.includeSMSDrips,
        includeRevampExtended: this.formModel.includeRevampExtended,
        includeFelloActionPlans: this.formModel.includeFelloActionPlans,
        includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
        includeRealScoutActionPlans: this.formModel.includeRealScoutActionPlans,
        includeYlopoActionPlans: this.formModel.includeYlopoActionPlans
      }
    },
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    subscription() {
      return this.$route.query.subscription === 'true'
    },
    depositAmount() {
      return this.totalAmount/2
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    depositDollarAmount() {
      return `$${this.depositAmount.toLocaleString('en')}`
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    zillowStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.ZILLOW_TWO_WAY_SYNC
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    customUserEnabling() {
      return !!this.formModel.setupEnabledFor && this.formModel.setupEnabledFor !== 'All users'
    },
    customUserEnablingPricing() {
      return this.pricing.CUSTOM_USER_ENABLING
    },
    customStagesPricing() {
      return this.pricing.CUSTOM_STAGES
    },
    submitText() {
      return this.subscription ? 'Submit and pay first installment of $100' : `Submit and pay ${this.depositDollarAmount} Deposit`
    },
    isKBC() {
      return this.setupType === SETUP_TYPE.KBC
    }
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addWebsite() {
      this.formModel.websites.push({ ...newWebsite });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    addBackend() {
      this.formModel.websiteBackends.push({ ...newWebsiteBackend });
    },
    removeBackend(idx) {
      this.formModel.websiteBackends.splice(idx, 1);
    },
    buildNotes() {
      const notes = _pick(this.formModel, [
        'setupEnabledFor',
        'websites',
        'onlineReviews',
        'youtube',
        'brokerage',
        'businessAddress',
        'multiMarketServicing',
        'includeRealScoutActionPlans',
        'includeFelloActionPlans',
        'includeConversionMonsterActionPlans',
        'includeYlopoActionPlans',
        'hasBombBombVideos',
        'bombbombUsername',
        'bombbombPassword',
        'ylopoUserSearchSiteLive',
        'ylopoUserBrandedSiteLive',
        'ylopoUserBrandedSiteUrl',
        'fubPixelInstalled',
        'websiteBackends',
        'includeSpanishActionPlans',
        'defaultLanguage',
        'includeLenderAddOn',
        'includeRevampExtended',
        'singleMortgageCompany',
        'lenderWebsiteUrl',
        'lenderOnlineReviews',
        'stageSetup',
        'includeSMSDrips',
        'textingService'
      ])

      if (this.isKBC) {
        const kathleenNotes = this.$refs.kathleen.getFields()
        return {
          ...notes,
          isKBC: true,
          ...kathleenNotes
        }
      } else {
        return notes
      }
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes();
        const billing = this.formModel.billing;
        const businessAddress = this.formModel.businessAddress
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize();
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          basicInfo: !this.keeId ? this.formModel.basicInfo : null,
          keeId: this.keeId || null,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          meta: {
            setupType: this.setupType,
            subscription: this.subscription,
            includeSpanishActionPlans: this.formModel.includeSpanishActionPlans,
            customStages: this.customizeStages,
            customUserEnabling: this.customUserEnabling,
            includeLenderAddOn: this.formModel.includeLenderAddOn,
            zillowStages: this.zillowStages,
            includeSMSDrips: this.formModel.includeSMSDrips,
            legacyPricing: this.legacyPricing,
            includeRevampExtended: this.formModel.includeRevampExtended,
            includeRealScoutActionPlans: this.formModel.includeRealScoutActionPlans,
            includeFelloActionPlans: this.formModel.includeFelloActionPlans,
            includeConversionMonsterActionPlans: this.formModel.includeConversionMonsterActionPlans,
            includeYlopoActionPlans: this.formModel.includeYlopoActionPlans
          },
          payment: {
            note: this.isKBC ? 'Kathleen Black - Deposit Payment' : 'Deposit Payment',
            isDeposit: true,
            billing,
            locationId,
            sourceId,
          },
          businessAddress
        })

        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
