<template>
  <FormPageLayout>
    <!-- <p>{{ person.meta.depositAmount }}</p> -->
    <div v-if="personError">
      <p class="text-center mt-10 text-xl">Could not find account.</p>
    </div>
    <div v-else-if="personLoaded" class="container mx-auto p-10">
      <p class="text-2xl font-semibold text-site-purple">Thanks for signing up!</p>
      <p class="text-lg mt-5">We will need the following information before we can start revamping your Sierra.</p>
      <!-- Sierra Credentials -->
      <section>
        <p class="font-semibold text-lg">Sierra Credentials</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.siteName" placeholder="Site Name" />
          <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
          <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
        </div>
      </section>
      <!-- Brokerage -->
      <section>
        <p class="font-semibold text-lg">Brokerage</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
        </div>
      </section>
      <!-- Business Address -->
      <section>
        <p class="font-semibold text-lg">Business Address</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
          <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
          <CountrySelect
            v-model="formModel.businessAddress.country"
            :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
          />
          <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
          <StateSelect
            v-model="formModel.businessAddress.state"
            :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
            :showProvinces="formModel.businessAddress.country === 'Canada'"
          />
          <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        </div>
      </section>
      <!-- Servicing Multiple Markets -->
      <section>
        <p class="font-semibold text-lg">Do you service more than one market (multiple states/provinces, regions)?</p>
        <div class="field" :class="{'field-block-error': v$.formModel.multiMarketServicing.$error}">
          <p v-if="v$.formModel.multiMarketServicing.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.multiMarketServicing.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.multiMarketServicing" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.multiMarketServicing" />
          <label>No</label>
        </div>
      </section>
       <!-- Online Reviews -->
       <section>
        <p class="font-semibold text-lg">Online Reviews <span class="text-base font-light text-gray-700">(Please provide any URLs to your online reviews i.e Google, Zillow, Facebook etc.)</span></p>
        <!-- <p class="text-base font-light text-gray-700">This is so you can ask for reviews in the post closing drips and build trust with potential clients in the nuture drips</p> -->
        <div class="inline-block">
          <div class="field bg-gray-100 p-5" v-for="(onlineReview, idx) in formModel.onlineReviews"
            :key="`online_review_${idx}`" :class="{ 'mt-5': idx !== 0 }">
            <input type="text" v-model="onlineReview.url" placeholder="http://review-url.com" />
            <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeOnlineReview(idx)">
              <font-awesome-icon icon="fa-solid fa-times" />
            </span>
          </div>
        </div>
        <p class="mt-5 font-semibold text-sm">Have more online review URLs?</p>
        <a @click="addOnlineReview" class="
          text-site-purple
          font-semibold
          mt-1
          inline-block
          underline
          cursor-pointer
        ">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Online Review URL
        </a>
      </section>
      <!-- YouTube Channel -->
      <section>
        <p class="font-semibold text-lg">YouTube Channel</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <div class="field">
            <VTextField :v="v$.formModel.youtube" placeholder="YouTube Channel URL" />
          </div>
        </div>
      </section>
      <!-- Conversion Monster -->
      <section>
        <p class="font-semibold text-lg">Do you use Conversion Monster?</p>
        <div class="field" :class="{
          'field-block-error': v$.formModel.usesConversionMonster.$error,
        }">
          <p v-if="v$.formModel.usesConversionMonster.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.usesConversionMonster.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.usesConversionMonster" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.usesConversionMonster" />
          <label>No</label>
        </div>
      </section>
      <!-- BombBomb -->
      <section>
        <p class="font-semibold text-lg">Do you have BombBomb videos?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.hasBombBombVideos.$error }">
          <p v-if="v$.formModel.hasBombBombVideos.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.hasBombBombVideos.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.hasBombBombVideos" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.hasBombBombVideos" />
          <label>No</label>
        </div>
      </section>
      <section v-if="formModel.hasBombBombVideos === 'Yes'">
        <p class="font-semibold text-lg">BombBomb Credentials</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.bombbombUsername" placeholder="Username" autocomplete="off" />
          <VTextField :v="v$.formModel.bombbombPassword" placeholder="Password" autocomplete="off" />
        </div>
      </section>
      <!-- Ylopo Users -->
      <section>
        <p class="font-semibold text-lg">Are you a Ylopo user?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.ylopoUser.$error }">
          <p v-if="v$.formModel.ylopoUser.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.ylopoUser.$errors[0].$message }}
          </p>
          <input type="radio" id="ylopo-user-yes" value="Yes" v-model="formModel.ylopoUser" />
          <label for="ylopo-user-yes">Yes</label>
          <br />
          <input type="radio" id="ylopo-user-no" value="No" v-model="formModel.ylopoUser" />
          <label for="ylopo-user-no">No</label>
        </div>
      </section>
      <!-- Ylopo Users - Yes - Search Site Live -->
      <div v-if="formModel.ylopoUser === 'Yes'">
        <section>
          <p class="font-semibold text-lg">Is your search site live?</p>
          <div class="field" :class="{
            'field-block-error': v$.formModel.ylopoUserSearchSiteLive.$error,
          }">
            <p v-if="v$.formModel.ylopoUserSearchSiteLive.$error" class="field-error text-xs text-red-500">
              {{ v$.formModel.ylopoUserSearchSiteLive.$errors[0].$message }}
            </p>
            <input type="radio" id="ylopo-search-site-live-yes" value="Yes"
              v-model="formModel.ylopoUserSearchSiteLive" />
            <label for="ylopo-search-site-live-yes">Yes</label>
            <br />
            <input type="radio" id="ylopo-search-site-live-no" value="No" v-model="formModel.ylopoUserSearchSiteLive" />
            <label for="ylopo-search-site-live-no">No</label>
          </div>
        </section>
        <section>
          <p class="font-semibold text-lg">Is your branded website live?</p>
          <div class="field" :class="{
            'field-block-error': v$.formModel.ylopoUserBrandedSiteLive.$error,
          }">
            <p v-if="v$.formModel.ylopoUserBrandedSiteLive.$error" class="field-error text-xs text-red-500">
              {{ v$.formModel.ylopoUserBrandedSiteLive.$errors[0].$message }}
            </p>
            <input type="radio" id="ylopo-branded-site-live-yes" value="Yes"
              v-model="formModel.ylopoUserBrandedSiteLive" />
            <label for="ylopo-branded-site-live-yes">Yes</label>
            <br />
            <input type="radio" id="ylopo-branded-site-live-no" value="No"
              v-model="formModel.ylopoUserBrandedSiteLive" />
            <label for="ylopo-branded-site-live-no">No</label>
            <br />
            <input type="radio" id="ylopo-branded-site-live-not-using" value="Not Using"
              v-model="formModel.ylopoUserBrandedSiteLive" />
            <label for="ylopo-branded-site-live-not-using">Not using branded site</label>
          </div>
        </section>
        <section v-if="formModel.ylopoUserBrandedSiteLive === 'Yes'">
          <p class="font-semibold text-lg">Branded Website URL</p>
          <div class="inline-block bg-gray-100 p-5 rounded pt-3">
            <VTextField :v="v$.formModel.ylopoUserBrandedSiteUrl" placeholder="URL" autocomplete="off" />
          </div>
        </section>
      </div>
      <!-- Lender Action Plans -->
      <section v-if="includeLenderAddOn">
        <p class="font-semibold text-lg">Do all lenders in your account work for the same mortgage company?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.singleMortgageCompany.$error }">
          <p v-if="v$.formModel.singleMortgageCompany.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.singleMortgageCompany.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.singleMortgageCompany" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.singleMortgageCompany" />
          <label>No</label>
        </div>
      </section>
      <section v-if="formModel.singleMortgageCompany === 'Yes'">
        <p class="font-semibold text-lg">Lender Website</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <div class="field">
            <VTextField :v="v$.formModel.lenderWebsiteUrl" placeholder="Website URL" />
          </div>
        </div>
      </section>
      <section v-if="formModel.singleMortgageCompany === 'Yes'">
        <p class="font-semibold text-lg">Online Reviews for Lender <span class="text-base font-light text-gray-700">(Please provide
          any URLs to reviews i.e Google, Facebook etc. Comma-seperated values)</span></p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <div class="field">
            <VTextField :v="v$.formModel.lenderOnlineReviews" placeholder="URL" />
          </div>
        </div>
      </section>
      <!-- Setup activation notice -->
      <div class="p-10 mt-10 border bg-gray-100">
        <h3 class="text-lg font-semibold">Activating your new setup</h3>
        <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the setup before it goes live.</p>
        <p class="mt-5"><strong>Please note:</strong> Balance payment for the setup is due within two weeks from the setup completion date, irrespective of whether you choose immediate activation or a review period.</p>
      </div>
      <section>
        <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.agreeToConditions.$errors[0].$message }}
          </p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2 font-medium">I agree to
            <a @click="showConditionAgreement = true" class="link">these conditions</a> <span class="font-light">(click the link to view terms and conditions)</span></label>
        </div>
      </section>
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">Submit</button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
      <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" type="sierra" />
      <SubmittingModal
        v-if="isSubmitting"
        @close="(isSubmitting = false)"
        :loading="loading"
        :errorMessage="error"
        :showNextSteps="true"
      >
        <template #success>
          <div>
            <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
            <p class="text-xl pt-5">Your information has been submitted successfully</p>
          </div>
        </template>
      </SubmittingModal>
    </div>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import _get from 'lodash/get'
import { SETUP_TYPE } from '../../../constants';

const newOnlineReview = {
  url: ""
};
export default {
  mixins: [formValidationMixin],
  props: ["keeId"],
  data() {
    return {
      loading: false,
      error: '',
      person: {},
      personError: false,
      isSubmitting: false,
      showConditionAgreement: false,
      formModel: {
        siteName: "",
        crmUsername: "",
        crmPassword: "",
        onlineReviews: [{ ...newOnlineReview }],
        youtube: "",
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        singleMortgageCompany: "",
        lenderWebsiteUrl: "",
        lenderOnlineReviews: "",
        multiMarketServicing: "",
        usesConversionMonster: "",
        usesRealScout: "",
        usesFello: "",
        hasBombBombVideos: "",
        bombbombUsername: "",
        bombbombPassword: "",
        ylopoUser: "",
        ylopoUserSearchSiteLive: "",
        ylopoUserBrandedSiteLive: "",
        ylopoUserBrandedSiteUrl: "",
        agreeToConditions: "",
      },
    };
  },
  validations() {
    return {
      formModel: {
        siteName: { required },
        crmUsername: { required },
        crmPassword: { required },
        youtube: {},
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        multiMarketServicing: { required },
        usesRealScout: { required },
        usesFello: { required },
        usesConversionMonster: { required },
        hasBombBombVideos: { required },
        bombbombUsername: { required: requiredIf(this.usesBombBomb) },
        bombbombPassword: { required: requiredIf(this.usesBombBomb) },
        ylopoUser: { required },
        ylopoUserSearchSiteLive: { required: requiredIf(this.ylopoUser) },
        ylopoUserBrandedSiteLive: { required: requiredIf(this.ylopoUser) },
        ylopoUserBrandedSiteUrl: { required: requiredIf(this.brandedSiteLive) },
        agreeToConditions: { required },
        singleMortgageCompany: { required: requiredIf(this.includeLenderAddOn) },
        lenderWebsiteUrl: { required: requiredIf(this.formModel.singleMortgageCompany === 'Yes') },
        lenderOnlineReviews: {},
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.SIERRA
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    usesBombBomb() {
      return this.formModel.hasBombBombVideos === "Yes";
    },
    ylopoUser() {
      return this.formModel.ylopoUser === "Yes";
    },
    brandedSiteLive() {
      return (
        this.ylopoUser && this.formModel.ylopoUserBrandedSiteLive === "Yes"
      );
    },
    includeLenderAddOn() {
      return !!_get(this.person, 'meta.lenderAddOn')
    },
    zillowStages() {
      return !!_get(this.person, 'meta.zillowStages')
    },
    customStages() {
      return !!_get(this.person, 'meta.customStages')
    },
    spanishActionPlans() {
      return !!_get(this.person, 'meta.spanishActionPlans')
    },
    customUserEnabling() {
      return !!_get(this.person, 'meta.customUserEnabling')
    },
    personLoaded() {
      return !!this.person.meta
    }
  },
  watch: {
    usesBombBomb(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.bombbombUsername = "";
        this.formModel.bombbombPassword = "";
      }
    },
    ylopoUser(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.ylopoUserSearchSiteLive = "";
        this.formModel.ylopoUserBrandedSiteLive = "";
        this.formModel.ylopoUserBrandedSiteUrl = "";
      }
    },
    brandedSiteLive(newVal, oldVal) {
      if (oldVal && !newVal) {
        this.formModel.ylopoUserBrandedSiteUrl = "";
      }
    },
  },
  async created() {
    try {
      this.person = await this.$service.getPerson(this.keeId)
    } catch (e) {
      this.personError = true
    }
  },
  methods: {
    openRouteInNewWindow(routeName) {
      window.open(this.$router.resolve({ name: routeName }).href, '_blank')
    },
    addOnlineReview() {
      this.formModel.onlineReviews.push({ ...newOnlineReview });
    },
    removeOnlineReview(idx) {
      this.formModel.onlineReviews.splice(idx, 1);
    },
    buildNotes() {
      let buildNotes = _pick(this.formModel, [
        'onlineReviews',
        'youtube',
        'brokerage',
        'businessAddress',
        'multiMarketServicing',
        'usesConversionMonster',
        'hasBombBombVideos',
        'bombbombUsername',
        'bombbombPassword',
        'ylopoUser',
        'ylopoUserSearchSiteLive',
        'ylopoUserBrandedSiteLive',
        'ylopoUserBrandedSiteUrl',
        'singleMortgageCompany',
        'lenderWebsiteUrl',
        'lenderOnlineReviews',
      ])

      buildNotes.spanishActionPlans = this.spanishActionPlans ? 'Yes' : 'No'
      buildNotes.lenderAddOn = this.includeLenderAddOn ? 'Yes' : 'No'

      return buildNotes
    },
    async submit() {
      if (!(await this.v$.$validate())) return
      
      try {
        this.error = ''
        this.loading = true;
        this.isSubmitting = true;
        const notes = this.buildNotes();

        await this.$service.manualSetupData({
          keeId: this.keeId,
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          businessAddress: this.formModel.businessAddress,
          meta: {
            setupType: this.setupType,
            sierraSiteName: this.formModel.siteName
          }
        })

        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
