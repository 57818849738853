<template>
  <FormPageLayout>
    <Modal v-if="!modalProceeded" class="text-center">
      <p class="text-2xl">Thank you for your interest in our texting drips!</p>
      <p class="mt-5 text-xl"><strong>Please note:</strong></p>
      <div class="mt-5 border p-5 inline-block text-lg text-gray-700">
        <p>You must already be set up with {{ textingService.name }} <strong class="font-semibold">before</strong> we implement our texting drips.</p>
        <p class="mt-5">If you are not currently set up with {{ textingService.name }} <strong class="font-semibold">we can not implement our texting drips.</strong></p>
      </div>
      <p class="mt-5">Before proceeding to the sign-up form, please indicate below that you are already set up with {{ textingService.name }}</p>
      <form @submit.prevent="modalProceeded = true" class="mt-5">
        <div class="flex justify-center items-center">
          <input type="checkbox" @change="textingServiceAcknowledged = !textingServiceAcknowledged" />
          <p class="ml-3">I indicate that I am already set up with {{ textingService.name }}</p>
        </div>
        <div>
          <button type="submit" class="button mt-5" :disabled="!textingServiceAcknowledged">Proceed</button>
        </div>
      </form>
      <p class="mt-5">Not set up with {{ textingService.name }}? Visit <a class="link" :href="textingService.websiteUrl" target="_blank">{{ textingService.websiteUrl }}</a> to sign up.</p>
    </Modal>
    <div class="container mx-auto p-10">
      <p class="text-2xl font-semibold text-site-purple">Thanks for signing up for our texting drips!</p>
      <p class="text-lg mt-5">We will need the following information before we can set you up.</p>
      <!-- Basic Info -->
      <section>
        <p class="font-semibold text-lg">Your Info</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.firstName" placeholder="First Name"/>
          <VTextField :v="v$.formModel.lastName" placeholder="Last Name"/>
          <VTextField :v="v$.formModel.email" placeholder="Email"/>
          <VTextField :v="v$.formModel.phone" placeholder="Phone Number"/>
        </div>
      </section>
      <!-- Follow Up Boss Credentials -->
      <section>
        <p class="font-semibold text-lg">
          Follow Up Boss Credentials
          <span class="text-base font-light text-gray-700">(Owner Account)</span>
        </p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.crmUsername" placeholder="Username" autocomplete="off" />
          <VTextField :v="v$.formModel.crmPassword" placeholder="Password" autocomplete="off" />
        </div>
      </section>
      <!-- Website URL(s) -->
      <section class="inline-block">
        <p class="font-semibold text-lg -mb-2">Website URL</p>
        <div class="field bg-gray-100 p-5" v-for="(website, idx) in formModel.websites" :key="`website_${idx}`"
          :class="{ 'mt-5': idx !== 0 }">
          <input type="text" v-model="website.url" placeholder="http://mywebsite.com" />
          <span v-if="idx !== 0" class="cursor-pointer ml-2" @click="removeWebsite(idx)">
            <font-awesome-icon icon="fa-solid fa-times" />
          </span>
        </div>
        <p class="mt-5 font-semibold text-sm">Have multiple websites?</p>
        <a @click="addWebsite" class="
          text-site-purple
          font-semibold
          mt-1
          inline-block
          underline
          cursor-pointer
        ">
          <font-awesome-icon icon="fa-solid fa-plus-circle" />
          Add Website
        </a>
      </section>
      <!-- Brokerage -->
      <section>
        <p class="font-semibold text-lg">Brokerage</p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <VTextField :v="v$.formModel.brokerage" placeholder="i.e Keller Williams Realty" />
        </div>
      </section>
      <!-- Business Address -->
      <section>
      <p class="font-semibold text-lg">Business Address</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.businessAddress.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.businessAddress.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.businessAddress.country"
          :errorMessage="v$.formModel.businessAddress.country.$error ? v$.formModel.businessAddress.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.businessAddress.city" placeholder="City" />
        <StateSelect
          v-model="formModel.businessAddress.state"
          :errorMessage="v$.formModel.businessAddress.state.$error ? v$.formModel.businessAddress.state.$errors[0].$message : ''"
          :showProvinces="formModel.businessAddress.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.businessAddress.zip" :placeholder="formModel.businessAddress.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
      </div>
    </section>
      <!-- RealScout -->
      <section>
        <p class="font-semibold text-lg">Do you use RealScout?</p>
        <div class="field" :class="{
          'field-block-error': v$.formModel.usesRealScout.$error,
        }">
          <p v-if="v$.formModel.usesRealScout.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.usesRealScout.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.usesRealScout" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.usesRealScout" />
          <label>No</label>
        </div>
      </section>
      <!-- Fello -->
      <section>
        <p class="font-semibold text-lg">Do you use Fello?</p>
        <div class="field" :class="{
          'field-block-error': v$.formModel.usesFello.$error,
        }">
          <p v-if="v$.formModel.usesFello.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.usesFello.$errors[0].$message }}
          </p>
          <input type="radio" value="Yes" v-model="formModel.usesFello" />
          <label>Yes</label>
          <br />
          <input type="radio" value="No" v-model="formModel.usesFello" />
          <label>No</label>
        </div>
      </section>
      <!-- Ylopo User -->
      <section>
        <p class="font-semibold text-lg">Are you a Ylopo user?</p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.ylopoUser.$error }">
          <p v-if="v$.formModel.ylopoUser.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.ylopoUser.$errors[0].$message }}
          </p>
          <input type="radio" id="ylopo-user-yes" value="Yes" v-model="formModel.ylopoUser" />
          <label for="ylopo-user-yes">Yes</label>
          <br />
          <input type="radio" id="ylopo-user-no" value="No" v-model="formModel.ylopoUser" />
          <label for="ylopo-user-no">No</label>
        </div>
      </section>
      <section>
        <p class="font-semibold text-lg">Setting up stages that will trigger the lead nurture SMS drips<br /><span class="link underline text-base font-normal" @click="showStagesModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see stage definitions</span></p>
        <div class="field" :class="{ 'field-block-error': v$.formModel.stageSetup.$error }">
          <p v-if="v$.formModel.stageSetup.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.stageSetup.$errors[0].$message }}
          </p>
          <input type="radio" :value="stageSetupTypes.DEFAULT" v-model="formModel.stageSetup" />
          <label>Use the default stages provided by the setup (recommended for most)</label>
          <br />
          <input type="radio" :value="stageSetupTypes.ZILLOW_TWO_WAY_SYNC" v-model="formModel.stageSetup" />
          <label>I want to use the Zillow Stages via the 2-way sync with Zillow - more info <a class ="link" href="https://help.followupboss.com/hc/en-us/articles/8895957403927" target="_blank">here</a></label>
          <br />
          <input type="radio" :value="stageSetupTypes.CUSTOM" v-model="formModel.stageSetup" />
          <label>I would like to opt out of using the default stages used by the setup. Instead, I have a requirement that the setup be customized to use predefined stages that I will provide to Kee Technology Solutions. I acknolowege that I must provide the names and definitions of my stages before work can be started. <strong>I agree to pay a $200 customization fee.</strong> (Note: We will reach out to you after your submission to obtain a list of stages we can use)</label>
        </div>
      </section>
      <!-- Setup activation notice -->
      <div class="p-10 mt-10 border bg-gray-100">
        <h3 class="text-lg font-semibold">Activating your new setup</h3>
        <p class="mt-5">After the setup of your new action plans and automations is complete, we will notify you via
          email. At this stage, you can either approve immediate activation or opt for a review period to evaluate the
          setup before it goes live.</p>
      </div>
      <!-- Project Price -->
      <section class="inline-block border p-10 bg-gray-100 text-gray-700">
        <p class="text-xl font-medium">Project Price</p>
        <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
        <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
      </section>
      <PriceBreakdownModal
        v-if="showPriceBreakdownModal"
        @close="showPriceBreakdownModal = false"
        :pricing="pricing"
        :pricingMeta="pricingMeta"
      />
      <!-- Payment -->
      <section>
        <p class="text-lg font-semibold">Payment <span class="text-sm font-normal">Due Now</span></p>
        <p class="text-lg font-semibold">
          Amount: {{totalDollarAmount}} <span class="text-sm font-normal">(USD)</span>
        </p>
        <div class="inline-block bg-gray-100 p-5 rounded pt-3">
          <p class="font-semibold">Billing Information</p>
          <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
          <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
          <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
          <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
          <CountrySelect
            v-model="formModel.billing.country"
            :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
          />
          <VTextField :v="v$.formModel.billing.city" placeholder="City" />
          <StateSelect
            v-model="formModel.billing.state"
            :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
            :showProvinces="formModel.billing.country === 'Canada'"
          />
          <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
          <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
          <p class="mt-5 font-semibold">Card Information</p>
          <SquarePayment ref="squarePayment" />
        </div>
      </section>
      <!-- Agreement -->
      <section>
        <div class="field" :class="{ 'field-block-error': v$.formModel.agreeToConditions.$error }">
          <p v-if="v$.formModel.agreeToConditions.$error" class="field-error text-xs text-red-500">
            {{ v$.formModel.agreeToConditions.$errors[0].$message }}
          </p>
          <input type="checkbox" v-model="formModel.agreeToConditions" true-value="agree" false-value="" />
          <label class="ml-2 font-medium">I agree to <a @click="showConditionAgreement = true" class="link">these conditions</a></label>
        </div>
      </section>
      <button @click="submit" :disabled="!canSubmit" class="button mt-5">
        Submit and Pay {{totalDollarAmount}}
      </button>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
      <StagesModal v-if="showStagesModal" @close="showStagesModal = false" />
      <ConditionAgreementModal v-if="showConditionAgreement" @close="showConditionAgreement = false" type="sms" />
      <SubmittingModal
        v-if="isSubmitting"
        @close="(isSubmitting = false)"
        :loading="loading"
        :errorMessage="error"
        :showNextSteps="true"
        :receiptUrl="receiptUrl"
      >
        <template #success>
          <div>
            <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
            <p class="text-xl pt-5">Your information has been submitted successfully and the payment of <strong class="font-semibold">{{totalDollarAmount}}</strong> has been processed.</p>
          </div>
        </template>
      </SubmittingModal>
    </div>
  </FormPageLayout>
</template>
<script>
import { required, formValidationMixin } from '@/mixins/formValidateMixin'
import _pick from 'lodash/pick'
import StagesModal from "./StagesModal";
import { SETUP_TYPE, STAGE_SETUP_TYPES, SMS_VENDOR } from "../../../constants"
import { getPricing } from '@/pricing'

const newWebsite = {
  url: "",
};

export default {
  mixins: [formValidationMixin],
  components: { StagesModal },
  data() {
    return {
      loading: false,
      error: "",
      isSubmitting: false,
      showConditionAgreement: false,
      showStagesModal: false,
      textingServiceAcknowledged: false,
      showPriceBreakdownModal: false,
      modalProceeded: false,
      receiptUrl: "",
      formModel: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        crmUsername: "",
        crmPassword: "",
        websites: [{ ...newWebsite }],
        brokerage: "",
        businessAddress: {
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
        },
        ylopoUser: "",
        usesRealScout: "",
        usesFello: "",
        agreeToConditions: "",
        stageSetup: "",
        billing: {
          firstName: "",
          lastName: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
      }
    }
  },
  validations() {
    return {
      formModel: {
        firstName: { required },
        lastName: { required },
        email: { required },
        phone: { required },
        crmUsername: { required },
        crmPassword: { required },
        brokerage: { required },
        businessAddress: {
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
        },
        ylopoUser: { required },
        stageSetup: { required },
        usesFello: { required },
        usesRealScout: { required },
        agreeToConditions: { required },
        billing: {
          firstName: { required },
          lastName: { required },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    vendor() {
      return this.$route.query.vendor || ''
    },
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    pricingMeta() {
      return {
        customizeStages: this.customizeStages
      }
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    ylopoUser() {
      return this.formModel.ylopoUser === "Yes";
    },
    textingService() {
      switch (this.vendor) {
        case SMS_VENDOR.LEADNGAGE: return { name: 'Leadngage', websiteUrl: 'https://www.leadngage.com' }
        case SMS_VENDOR.STREET_TEXT: return { name: 'StreetText', websiteUrl: 'https://streettext.com/' }
        case SMS_VENDOR.TEXTING_BETTY:
        default: return { name: 'Texting Betty', websiteUrl: 'https://textingbetty.com' }
      }
    },
    vendorTag() {
      switch (this.vendor) {
        case SMS_VENDOR.LEADNGAGE: return 'Leadngage'
        case SMS_VENDOR.STREET_TEXT: return 'StreetText Setup'
        case SMS_VENDOR.TEXTING_BETTY:
        default: return 'Texting Betty'
      }
    },
    customizeStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.CUSTOM
    },
    zillowStages() {
      return this.formModel.stageSetup === STAGE_SETUP_TYPES.ZILLOW_TWO_WAY_SYNC
    },
    stageSetupTypes() {
      return STAGE_SETUP_TYPES
    },
    totalDollarAmount() {
      let amount = this.pricing.BASE

      if (this.customizeStages) {
        amount += this.pricing.CUSTOM_STAGES
      }

      return `$${amount.toLocaleString('en')}`
    },
    setupType() {
      return SETUP_TYPE.FUB_AGENT_SMS
    }
  },
  methods: {
    addWebsite() {
      this.formModel.websites.push({ ...newWebsite });
    },
    removeWebsite(idx) {
      this.formModel.websites.splice(idx, 1);
    },
    buildNotes() {
      const formModel = _pick(this.formModel, [
        'websites',
        'brokerage',
        'businessAddress',
        'stageSetup',
        'ylopoUser',
        'usesRealScout',
        'usesFello'
      ])
      return {
        ...formModel,
        textingService: this.textingService.name
      }
    },
    async submit() {
      if (!(await this.v$.$validate())) return

      try {
        const notes = this.buildNotes()
        const billing = this.formModel.billing
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        this.error = ""
        this.isSubmitting = true
        this.loading = true

        const { receiptUrl } = await this.$service.createSetup({
          basicInfo: {
            firstName: this.formModel.firstName,
            lastName: this.formModel.lastName,
            email: this.formModel.email,
            phone: this.formModel.phone,
          },
          crmPassword: this.formModel.crmPassword,
          crmUsername: this.formModel.crmUsername,
          notes,
          meta: {
            setupType: this.setupType,
            customStages: this.customizeStages,
            zillowStages: this.zillowStages,
            legacyPricing: this.legacyPricing
          },
          payment: {
            note: 'Texting Setup Payment',
            billing,
            locationId,
            sourceId,
          }
        })
        
        this.receiptUrl = receiptUrl
        this.loading = false
      } catch (e) {
        this.error = this.$service.getErrorMessage(e)
        this.loading = false
      }
    },
  }
}
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>