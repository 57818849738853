<template>
  <section class="py-20 px-6 text-white">
    <div class="container mx-auto">
      <div class="flex flex-col-reverse lg:flex-row">
        <div class="lg:pr-10">
          <h3 class="hidden lg:block text-4xl font-bold">Have any of these pain points? 👉</h3>
          <p class="mt-10 text-lg">Whether you are new to {{ crm }} or a veteran user, if you are looking for a curated and intuitive approach to using your CRM then you've come to the right place. We're here to help ensure you're utilizing {{ crm }} to its fullest potential. We offer a thorough setup comprised of smart lists, action plans, and automations that provide a simplified workflow and enable you to stay focused on what you do best: creating relationships and closing deals.</p>
        </div>
        <div class="bg-white text-black p-5 rounded-xl flex-shrink-0 font-medium">
          <p v-for="item, idx in painPoints" :key="`pain_point_${idx}`" class="py-3" :class="{ 'border-t': idx > 0 }">{{ item }}</p>
        </div>
        <h3 class="lg:hidden text-center text-4xl font-bold mb-10">Do any of these sound familiar?</h3>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  props: ['crm'],
  computed: {
    painPoints() {
      return [
        "I have a CRM, but I’m not really using it",
        "I have some action plans, but they’re not performing",
        "I don’t have the time to create drip campaigns",
        "I struggle with finding which leads to call",
        "I have too many tasks",
        "I don’t have a realistic daily workflow",
        "I feel like I’ve been going down rabbit holes",
        "I’m worried I’ll break something",
        `I’m struggling with SOPs for ${this.crm} within my company`
      ]
    }
  }
}
</script>