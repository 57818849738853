import Vue from 'vue';
import VueRouter from 'vue-router';
import { GUIDE_CODES, SETUP_TYPE, SMS_VENDOR } from './constants'

Vue.use(VueRouter);

import store from './store'

import Home from './components/pages/home'
import PageNotFound from'./components/pages/404'
import Training from'./components/pages/training'
import SetupForm from'./components/pages/setup-form'
import BalancePayment from'./components/pages/BalancePayment'
import TextingService from'./components/pages/texting-service'
import TextingSignUp from'./components/pages/texting-sign-up'
import Terms from'./components/pages/Terms'
import AgentReference from'./components/pages/agent-reference'
import GuideRedemptionForm from'./components/pages/GuideRedemptionForm'
import GuideView from'./components/pages/GuideView'
import KtsAi from './components/pages/KtsAi'
import Blog from './components/pages/blog'
import Post from './components/pages/blog/Post.vue'
import Lender from './components/pages/lender'
import LenderSignUp from './components/pages/lender/LenderSignUp'
import BossAssist from './components/pages/boss-assist/BossAssist.vue'
import BossAssistSignup from './components/pages/boss-assist/BossAssistSignup.vue'
import ManualSignup from './components/pages/setup-form/ManualSignup'
import ManualSetupData from './components/pages/setup-form/ManualSetupData'
import Sandbox from './components/pages/Sandbox'
import Workshop from './components/pages/Workshop'
import GroupTrainingLibrary from './components/pages/group-training/GroupTrainingLibrary'
import AffiliatePayout from './components/pages/affiliate/AffiliatePayout'
import AffiliateList from './components/pages/affiliate/AffiliateList'
import Discover from './components/pages/conversionly/Discover.vue'
import BookingConfirmation from './components/pages/booking-confirmation/BookingConfirmation.vue'
import TCAssist from './components/pages/tc-assist/TCAssist'
import TCAssistSignup from './components/pages/tc-assist/TCAssistSignup'
import Audit from './components/pages/Audit.vue'
import FelloAutomationsPromo from './components/pages/promo/FelloAutomationsPromo.vue'
import SevenPondPromo from './components/pages/promo/SevenPondPromo.vue'
import Creds from './components/pages/Creds.vue'
import SierraWaitList from './components/pages/promo/SierraWaitList.vue'
import Admin from './components/pages/admin/Admin.vue'
import Crypto from'./components/pages/admin/crypto.vue'
import PayoutNote from './components/pages/admin/PayoutNote.vue'
import MassApply from './components/pages/admin/MassApply.vue'
import SubscriptionCancelation from './components/pages/admin/SubscriptionCancelation.vue'
import SierraSignup from './components/pages/sierra/SierraSignup.vue'
import SierraManualSignup from './components/pages/sierra/ManualSignup.vue'
import SierraManualSetupData from './components/pages/sierra/ManualSetupData.vue'
import EmailTemplateFindReplace from'./components/pages/admin/EmailTemplateFindReplace'
import TaskAssign from './components/pages/admin/TaskAssign.vue'

const routes = [
  { path: "/", name: "home", component: Home, props: { setupType: SETUP_TYPE.FUB_AGENT_REVAMP } },
  { path: "/setup", redirect: { name: 'training', params: { slug: 'fub-revamp' } } },
  { path: "/training", redirect: { name: 'training', params: { slug: 'fub-revamp' } } },
  { path: "/training/:slug", name: "training", component: Training, props: true, meta: { internal: true } },
  { path: "/group-training/:keeId", name: "group-training", component: GroupTrainingLibrary, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.FUB_AGENT_REVAMP }) },
  { path: "/setup-form/:keeId?", component: SetupForm, alias: ['/sign-up/:keeId?', '/signup/:keeId?'], props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.FUB_AGENT_REVAMP }) },
  { path: "/setup-data/:keeId", component: ManualSetupData, props: true },
  { path: "/manual-signup", component: ManualSignup, meta: { internal: true } },
  { path: "/payment/balance/:keeId", component: BalancePayment, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.FUB_AGENT_REVAMP }) },
  { path: "/crypto", redirect: '/admin/crypto' },
  { path: "/texting-betty", name: "texting-betty", component: TextingService, props: { vendor: SMS_VENDOR.TEXTING_BETTY  } },
  { path: "/leadngage", name: "leadngage", component: TextingService, props: { vendor: SMS_VENDOR.LEADNGAGE  } },
  { path: "/street-text", alias: "/streettext", component: TextingService, props: { vendor: SMS_VENDOR.STREET_TEXT  } },
  { path: "/texting-sign-up", name: "texting-sign-up", alias: "/texting-signup", component: TextingSignUp },
  { path: "/kts-ai", name: "kts-ai", component: KtsAi },
  { path: "/agent-reference", name: "agent-reference", component: AgentReference, meta: { internal: true } },
  { path: "/terms/:type", name:"terms", component: Terms, meta: { internal: true }, props: true },
  { path: "/terms", redirect: "/terms/default" },
  { path: "/texting-setup-terms", redirect: "/terms/sms" },
  { path: "/lender", name: "lender", component: Lender, alias: "/lenders" },
  { path: "/lender/sign-up/:keeId?", alias: "/lender/signup/:keeId?", component: LenderSignUp, meta: { internal: true }, props: true },
  { path: "/solo", name: 'solo-agent-guide', alias: "/solo-agent-guide", component: GuideRedemptionForm, props: { guideType: GUIDE_CODES.SOLO } },
  { path: "/team-lead", name: 'team-lead-guide', component: GuideRedemptionForm, props: { guideType: GUIDE_CODES.TEAM_LEAD } },
  { path: "/guide/:slug", name: 'guide-view', component: GuideView, props: true, meta: { internal: true } },
  { path: "/blog", name: "blog", component: Blog, meta: { internal: true } },
  { path: '/post', redirect: { name: 'blog' }, meta: { internal: true } },
  { path: "/post/:slug", name: "post", component: Post, props: true, meta: { internal: true } },
  { path: "/boss-assist", name: "boss-assist", component: BossAssist, alias: "/bossassist" },
  { path: "/boss-assist/sign-up/:keeId?", alias: "/boss-assist/signup/:keeId?", component: BossAssistSignup, props: true },
  { path: "/boss-assist/group-training/:keeId", component: GroupTrainingLibrary, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.BOSS_ASSIST }) },
  { path: "/sandbox", component: Sandbox },
  { path: "/workshop", name: "workshop", component: Workshop, meta: { internal: true } },
  { path: "/affiliate-payout/:keeId", component: AffiliatePayout, meta: { internal: true }, props: true },
  { path: "/affiliate-list", component: AffiliateList, meta: { internal: true } },
  { path: "/discover", component: Discover },
  { path: "/booking-confirmation", component: BookingConfirmation, props: { setupType: SETUP_TYPE.FUB_AGENT_REVAMP } },
  { path: "/tc-assist", alias: "/tcassist", component: TCAssist },
  { path: "/tc-assist/sign-up/:keeId?", alias: "/tc-assist/signup/:keeId?", component: TCAssistSignup, props: true },
  { path: "/tc-assist/group-training/:keeId", component: GroupTrainingLibrary, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.TC_ASSIST }) },
  { path: "/tc-assist/balance/:keeId", component: BalancePayment, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.TC_ASSIST }) },
  { path: "/tc-assist/booking-confirmation", component: BookingConfirmation, props: { setupType: SETUP_TYPE.TC_ASSIST } },
  { path: "/audit", component: Audit },
  { path: "/fello-automations", component: FelloAutomationsPromo },
  { path: "/sierra/lading", component: Home, props: { setupType: SETUP_TYPE.SIERRA } },
  { path: "/sierra", alias: "/sierra-waitlist", component: SierraWaitList },
  { path: "/sierra/sign-up/:keeId", component: SierraSignup, props: true },
  { path: "/sierra/manual-signup", component: SierraManualSignup, props: true },
  { path: "/sierra/setup-data/:keeId", component: SierraManualSetupData, props: true },
  { path: "/sierra/group-training/:keeId", component: GroupTrainingLibrary, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.SIERRA }) },
  { path: "/sierra/balance/:keeId", component: BalancePayment, props: (route) => ({ keeId: route.params.keeId, setupType: SETUP_TYPE.SIERRA }) },
  { path: "/ponds", component: SevenPondPromo },
  {
    path: "/admin",
    component: Admin,
    meta: { internal: true },
    children: [
      { path: 'crypto', component: Crypto },
      { path: 'payout-note', component: PayoutNote },
      { path: "subscription/cancel", component: SubscriptionCancelation },
      { path: "mass-apply", component: MassApply },
      { path: "email-template-find-replace", component: EmailTemplateFindReplace },
    ]
  },
  { path: "/task-assign", component: TaskAssign },
  { path: "/credentials/:keeId", component: Creds, props: true },
  { path: "/kbc", component: Home, props: { setupType: SETUP_TYPE.KBC } },
  { path: "/kbc/sign-up/:keeId", component: SetupForm, props: { setupType: SETUP_TYPE.KBC } },
  { path: "*", name: "page-not-found", component: PageNotFound, meta: { internal: true } }
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior () {
    return { x: 0, y: 0 }
  }
})

router.beforeEach(async (to, from, next) => {
  const isInternalPage = to.matched.some(record => record.meta.internal)
  await store.dispatch('setBannerDisplay', !isInternalPage)
  next()
})

export default router;
