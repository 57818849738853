<template>
  <div class="text-center mt-10 container mx-auto">
    <div v-if="error !== ''">
      <h3>Whoops. An error has occurred.</h3>
      <p><strong>error message:</strong> {{ error }}</p>
    </div>

    <div v-else-if="stage === stages.API_KEY">
      <label>Insert API KEY</label><br>
      <input v-model="apiKey" class="form-input" type="text" /><br>
      <button :disabled="!apiKey" @click="toTag" class="button mt-5">Next</button>
    </div>

    <div v-else-if="stage === stages.TAG">
      <label>Specify Tag</label><br>
      <input v-model="tag" type="text" class="form-input"/><br>
      <button :disabled="!tag" @click="collectPeople" class="button mt-5">Next</button>
    </div>

    <div v-else-if="stage === stages.PEOPLE_COLLECTING">
      <div v-if="taggedPeople.length < 1">
        <p>Fetching leads tagged <strong>{{ tag }}</strong>...</p>
      </div>
      <div v-else>
        <p><strong>{{ taggedPeople.length.toLocaleString('en') }}</strong> leads tagged <strong>{{ tag }}</strong> have been fetched!</p>
        <div v-if="conflictingPeople.length > 1" class="mt-5">
          <p><strong>{{ conflictingPeople.length.toLocaleString('en') }}</strong> of those leads are on other plans and will be skipped for the mass apply.</p>
          <p class="mt-5"><strong>{{ peopleToProcess.length.toLocaleString('en') }}</strong> leads will be processed.</p>
          <div class="mt-5 bg-white rounded shadow-sm p-5">
            <p>Conflicting Plans</p>
            <p v-for="{ id, name } in conflictingPlans" :key="id" class="font-semibold my-2">{{ name }}</p>
          </div>
        </div>
        <button v-if="peopleToProcess.length" @click="stage = stages.ACTION_PLAN" class="button mt-5">Next</button>
      </div>
    </div>

    <div v-else-if="stage === stages.ACTION_PLAN">
      <p>Select an action plan for those tagged <strong>{{ tag }}</strong></p>
      <input class="form-input" placeholder="Search Plans" v-model="planSearchTerm" />
      <div class="h-64 max-w-lg mx-auto shadow bg-gray-100 overflow-scroll mt-5">
        <div
          class="border-b p-2 text-sm cursor-pointer hover:bg-blue-500 hover:text-white"
          :class="{'bg-blue-500 text-white': plan.id === selectedPlanId}"
          v-for="plan in filteredPlans"
          :key="plan.id"
          @click="selectedPlanId = plan.id"
        >
          {{ plan.name }}
        </div>
      </div>
      <div v-if="selectedPlanId !== ''" class="mt-5">
        <p>You have selected: <strong>{{ selectedPlan.name }}</strong></p>
        <button @click="processLeads" class="button mt-5">Apply Plan</button>
      </div>
    </div>

    <div v-else-if="stage === stages.WORKING">
      <h3>Working...</h3>
      <p><strong>Do not close this tab</strong></p>
      <p>Action plans are being added to the tagged leads</p>
      <p>
        <strong>{{ leadCountProcessed }} leads processed</strong> out of
        <strong>{{ peopleToProcess.length }} total leads</strong>
      </p>
    </div>

    <div v-else-if="stage === stages.COMPLETE">
      <h3>Process complete!</h3>
      <p>Action plan <strong>{{ selectedPlan.name }}</strong> has been applied to <strong>{{ peopleToProcess.length }}</strong> leads tagged <strong>{{ tag }}</strong>.</p>
      <div class="text-center">
        <button @click="applyMore" class="button mt-5">Continue with more mass apply work!</button>
      </div>
    </div>
  </div>
</template>

<script>
import chunk from 'lodash/fp/chunk'
import groupBy from 'lodash/fp/groupBy'
import reduce from 'lodash/reduce'
import intersectionWith from 'lodash/intersectionWith'
import difference from 'lodash/difference'
import fubApiMixin from '../../../mixins/fubApiMixin'

const APP_STAGES = {
  API_KEY: "API_KEY",
  TAG: "TAG",
  PEOPLE_COLLECTING: "PEOPLE_COLLECTING",
  ACTION_PLAN: "ACTION_PLAN",
  WORKING: "WORKING",
  COMPLETE: "COMPLETE",
}

export default {
  mixins: [fubApiMixin],
  data() {
    return {
      error: "",
      stage: APP_STAGES.API_KEY,
      stages: APP_STAGES,
      tag: "",
      selectedPlanId: "",
      actionPlans: [],
      runningPlans: [],
      taggedPeople: [],
      leadCountProcessed: 0,
      planSearchTerm: ""
    };
  },
  methods: {
    applyMore() {
      this.tag = ""
      this.selectedPlanId = ""
      this.actionPlans = [],
      this.taggedPeople = []
      this.leadCountProcessed = 0
      this.planSearchTerm = 0
      this.stage = this.stages.TAG
    },
    async toTag() {
      this.setupApi()
      await this.getActiveActionPlans()
      await this.getRunningPlans()
      this.stage = this.stages.TAG
    },
    async collectPeople() {
      this.stage = this.stages.PEOPLE_COLLECTING;
      await this.getTaggedPeople();
    },
    async getActiveActionPlans() {
      const getPlans = async (nextCursor = null, acc = []) => {
        const { _metadata: { next }, actionPlans } = await this.fubApi.getActionPlans({ status: 'Active', limit: 100, next: nextCursor })
        const allPlans = acc.concat(actionPlans)
        return next ? getPlans(next, allPlans) : allPlans
      }
      try {
        this.actionPlans = await getPlans()
      } catch (e) {
        this.error = e
      }
    },
    async getRunningPlans() {
      const getActionPlansPeople = async (nextCursor = null, acc = []) => {
        const { _metadata: { next }, actionPlansPeople } = await this.fubApi.getActionPlansPeople({ limit: 100, status: 'Running', next: nextCursor, })
        const allPlanPeople = acc.concat(actionPlansPeople)
        return next ? getActionPlansPeople(next, allPlanPeople) : allPlanPeople
      }
      try {
        this.runningPlans = await getActionPlansPeople()
      } catch (e) {
        this.error = e
      }
    },
    async getTaggedPeople() {
      const getPeople = async (nextCursor = null, acc = []) => {
        const { _metadata: { next }, people } = await this.fubApi.getPeople({ tags: this.tag, limit: 100, next: nextCursor })
        const allPeople = acc.concat(people)
        return next ? getPeople(next, allPeople) : allPeople
      }
      try {
        this.taggedPeople = await getPeople()
      } catch (e) {
        this.error = e
      }
    },
    async processLeads() {
      try {
        this.stage = this.stages.WORKING
        const actionPlanId = this.selectedPlanId
        // process 20 per second to max rate limit of 200 calls per 10 second sliding window
        const leadChunks = chunk(20)(this.peopleToProcess)
        for (const leadChunk of leadChunks) {
          for (const { id: personId } of leadChunk) {
            this.fubApi.applyActionPlan({ personId, actionPlanId })
            // console.log(`Added planId ${actionPlanId} to personId ${personId}`)
          }
          this.leadCountProcessed += leadChunk.length
          await new Promise(resolve => setTimeout(resolve, 1000))
        }
        this.stage = this.stages.COMPLETE
      } catch (e) {
        console.error(e)
        this.error = e
      }
    },
  },
  computed: {
    selectedPlan() {
      return this.actionPlans.find((plan) => plan.id === this.selectedPlanId);
    },
    conflictingPeople() {
      return intersectionWith(this.taggedPeople, this.runningPlans, ({ id }, { personId }) => id === personId)
    },
    peopleToProcess() {
      return difference(this.taggedPeople, this.conflictingPeople)
    },
    conflictingPlans() {
      const planIds = reduce(
        groupBy('actionPlanId')(this.runningPlans),
        (acc, planPeople, key) => {
          const matches = !!(intersectionWith(planPeople, this.conflictingPeople, ({ personId }, { id }) => personId === id)).length
          return matches ? [...acc, Number(key)] : acc
        },
        []
      )
      return intersectionWith(this.actionPlans, planIds, ({ id }, planId) => id === planId)
    },
    filteredPlans() {
      return this.actionPlans.filter(({ name }) => name.toLowerCase().includes(this.planSearchTerm.toLowerCase()))
    }
  },
};
</script>