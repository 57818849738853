<template>
  <LandingPageLayout @handleCTA="openSignUp" :navItems="navItems">
    <Hero ref="hero" :setupType="setupType" :affiliate="affiliate" :calendarUrl="calendarUrl" />
    <Benefits />
    <PainPoints :crm="crm" />
    <Testimonials />
    <WhatsIncluded :crm="crm" />
    <FAQ :faqList="faqList" />
    <Pricing @openSignUp="openSignUp" :basePrice="basePrice" :addOns="addOns" />
    <OtherServices class="md:hidden" />
    <Schedule @openSignUp="openSignUp" :calendarUrl="calendarUrl" />
  </LandingPageLayout>
</template>
<script>
import LandingPageLayout from '../../layouts/LandingPageLayout'
import Hero from '../../common/landing/Hero.vue'
import Benefits from './Benefits.vue'
import PainPoints from './PainPoints.vue'
import Testimonials from './Testimonials.vue'
import WhatsIncluded from './WhatsIncluded.vue'
import FAQ from '../../common/landing/FAQ.vue'
import Pricing from '../../common/landing/Pricing'
import Schedule from '../../common/landing/Schedule'
import OtherServices from './OtherServices.vue'
import navItems from './navItems'
import { mapGetters } from 'vuex'
import { SETUP_TYPE, CALENDLY_CALENDARS, CALENDLY_UTM } from '../../../constants.js'
import { getPricing } from '@/pricing'
import { buildCalendarUrl } from '../../../utils'

export default {
  components: {
    LandingPageLayout,
    Hero,
    Benefits,
    PainPoints,
    Testimonials,
    WhatsIncluded,
    FAQ,
    Pricing,
    Schedule,
    OtherServices,
  },
  props: {
    affiliate: {
      type: String,
      required: false,
      default: ''
    },
    setupType: {
      type: String,
      required: false,
      default: SETUP_TYPE.FUB_AGENT_REVAMP
    }
  },
  computed: {
    ...mapGetters(['agentRevampFaq', 'sierraFaq']),
    navItems() {
      return navItems
    },
    pricing() {
      return getPricing(this.setupType)
    },
    basePrice() {
      return this.pricing.BASE.toLocaleString('en')
    },
    faqList() {
      switch (this.setupType) {
        case SETUP_TYPE.SIERRA: return this.sierraFaq
        default: return this.agentRevampFaq
      }
    },
    crm() {
      return this.setupType === SETUP_TYPE.SIERRA ? 'Sierra' : 'Follow Up Boss'
    },
    addOns() {
      let addOns = []

      if (this.pricing.REVAMP_EXTENDED) {
        addOns.push({
          title: 'Revamp Extended',
          subtext: '* Additional action plans - Luxury Buyer and Seller, New Construction, Probate, Investor Buyer, Land Buyer',
          price: this.pricing.REVAMP_EXTENDED.toLocaleString('en')
        })
      }

      addOns.push({
        title: 'Platform Pairing',
        subtext: '* Additional action plans to support the following - Ylopo, RealScout, Fello, Conversion Monster',
        price: '100 per platform' 
      })

      if (this.pricing.SPANISH_ACTION_PLANS) {
        addOns.push({
          title: 'Spanish Action Plans',
          price: this.pricing.SPANISH_ACTION_PLANS.toLocaleString('en'),
        })
      }

      if (this.pricing.LENDER_ADD_ON) {
        addOns.push({
          title: 'Lender Action Plans',
          price: this.pricing.LENDER_ADD_ON.toLocaleString('en'),
        })
      }

      if (this.pricing.SMS_DRIPS) {
        addOns.push({
          title: 'SMS Drips',
          subtext: '* Must have Leadngage, Texting Betty, or StreetText',
          price: this.pricing.SMS_DRIPS.toLocaleString('en'),
        })
      }

      return addOns
    },
    calendarUrl() {
      let utm

      if (this.affiliate) {
        utm = CALENDLY_UTM.AFFILIATE_DISCOVERY(this.affiliate)
      } else if (this.setupType === SETUP_TYPE.KBC) {
        utm = CALENDLY_UTM.KBC
      } else if (this.setupType === SETUP_TYPE.SIERRA) {
        utm = CALENDLY_UTM.SIERRA_DISCOVERY
      } else {
        utm = CALENDLY_UTM.DISCOVERY
      }

      return buildCalendarUrl({ calendar: CALENDLY_CALENDARS.DISCOVERY, utm })
    },
  },
  methods: {
    openSignUp() {
      this.$refs.hero.openSignUp()
    }
  },
}
</script>