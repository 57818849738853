<template>
  <section :class="{'border-b border-gray-300 py-10 px-5': !embed, 'mt-5': embed }">
    <p class="font-semibold text-lg">{{ title }}<span v-if="helperText" class="text-base font-normal text-gray-700 ml-2">{{ helperText }}</span></p>
    <div>
      <slot></slot>
    </div>
  </section>
</template>
<script>
export default {
  props: ['title', 'helperText', 'embed']
}
</script>