<template>
  <FormPageLayout>
    <section>
      <p class="font-semibold text-lg">Sales Representative</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <SalesRepSelect
          v-model="formModel.salesRepresentative"
          :errorMessage="v$.formModel.salesRepresentative.$error ? v$.formModel.salesRepresentative.$errors[0].$message : ''" />
      </div>
    </section>
    <!-- Basic Info -->
    <section>
      <p class="font-semibold text-lg">Client Info</p>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.basicInfo.firstName" placeholder="First Name"/>
        <VTextField :v="v$.formModel.basicInfo.lastName" placeholder="Last Name"/>
        <VTextField :v="v$.formModel.basicInfo.email" placeholder="Email"/>
        <VTextField :v="v$.formModel.basicInfo.phone" placeholder="Phone Number"/>
      </div>
    </section>
    <section>
      <p class="font-semibold text-lg">Referral?</p>
      <div class="field" :class="{'field-block-error': v$.formModel.referral.$error}">
        <p v-if="v$.formModel.referral.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.referral.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.referral" />
        <label>Yes</label>
        <br />
        <input type="radio" value="No" v-model="formModel.referral" />
        <label>No</label>
      </div>
    </section>
    <section v-if="formModel.referral === 'Yes'">
      <div class="inline-block bg-gray-100 p-5 rounded pt-3">
        <VTextField :v="v$.formModel.referredFrom" placeholder="Referred by"/>
        <p class="my-2 font-medium">or affiliate referral selection</p>
        <AffiliateSelect
          v-model="formModel.affiliate"
          :errorMessage="v$.formModel.affiliate.$error ? v$.formModel.affiliate.$errors[0].$message : ''" />
      </div>
    </section>
    <!-- Spanish Content -->
    <section>
      <p class="font-semibold text-lg">Include Spanish Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.SPANISH_ACTION_PLANS }})</span></p>
      <p class="text-sm font-semibold">PLEASE NOTE: We do not provide Spanish translations for our Canadian market material.</p>
      <p><a class="link underline text-base font-normal" @click="showSpanishContentModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> more info</a></p>
      <SpanishContentModal v-if="showSpanishContentModal" @close="showSpanishContentModal = false" />
      <div class="field" :class="{ 'field-block-error': v$.formModel.spanishActionPlans.$error }">
        <p v-if="v$.formModel.spanishActionPlans.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.spanishActionPlans.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.spanishActionPlans" />
        <label>Yes</label> <span class="text-sm">(<strong>Please note:</strong> After the setup is complete, your review will be required before we can go live with it.)</span>
        <br />
        <input type="radio" value="No" v-model="formModel.spanishActionPlans" />
        <label>No</label>
      </div>
    </section>
    <section v-if="includeSpanishActionPlans">
      <p class="font-semibold text-lg">Default Language</p>
      <p class="text-sm">Used when language preference is unknown for a lead</p>
      <VRadio :v="v$.formModel.defaultLanguage" :options="['English', 'Spanish']" />
    </section>
    <!-- Lender Action Plans -->
    <section>
      <p class="font-semibold text-lg">Include Lender Action Plans? <span class="text-base font-normal">(Additional ${{ pricing.LENDER_ADD_ON }})</span></p>
      <p class="text-sm font-semibold mt-2">PLEASE NOTE: By selecting 'Yes' you agree to the terms that the lenders in your Follow Up Boss account are in fact "Lender" type users. The lender action plans will not work otherwise.</p>
      <p class="text-sm font-semibold mt-2">PLEASE NOTE: We do not provide Spanish translations for our lender action plans at this time.</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.lenderAddOn.$error }">
        <p v-if="v$.formModel.lenderAddOn.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.lenderAddOn.$errors[0].$message }}
        </p>
        <input type="radio" value="Yes" v-model="formModel.lenderAddOn" />
        <label>Yes</label> <span class="text-sm"> (<strong>Please note:</strong> After the setup is complete, your review will be required before we can go live with it.)</span>
        <br />
        <input type="radio" value="No" v-model="formModel.lenderAddOn" />
        <label>No</label>
      </div>
    </section>
    <!-- Custom Work -->
    <section>
      <p class="font-semibold text-lg">Include Custom Work?</p>
      <div class="field" :class="{ 'field-block-error': v$.formModel.customWorkNeeded.$error }">
        <p v-if="v$.formModel.customWorkNeeded.$error" class="field-error text-xs text-red-500">
          {{ v$.formModel.customWorkNeeded.$errors[0].$message }}
        </p>
        <input type="radio" value="Hourly Work" v-model="formModel.customWorkNeeded" />
        <label>Hourly Work</label> <span class="text-sm"></span>
        <br />
        <input type="radio" value="Flat Rate Work" v-model="formModel.customWorkNeeded" />
        <label>Flat Rate Work</label> <span class="text-sm"></span>
        <br />
        <input type="radio" value="No" v-model="formModel.customWorkNeeded" />
        <label>No</label>
      </div>
    </section>
    <section v-if="customWorkNeeded === 'Flat Rate Work'">
      <p class="font-semibold text-lg">Custom Work Amount</p>
      <VNumberField :v="v$.formModel.customWorkAmount" isDollarAmount="true" />
    </section>
    <section v-if="customWorkNeeded && customWorkNeeded !== 'No'">
      <p class="font-semibold text-lg">Custom Work Notes</p>
      <VTextArea :v="v$.formModel.customWorkNotes" placeholder="Add notes here..." />
    </section>
    <!-- Project Price -->
    <section class="inline-block border p-10 bg-gray-100 text-gray-700">
      <p class="font-medium">Discount</p>
      <VNumberField :v="v$.formModel.discountAmount" :isDollarAmount="true" />
      <hr class="my-5 border-gray-400">
      <p class="text-xl font-medium">Project Price</p>
      <p class="text-lg font-medium mt-2">{{ totalDollarAmount }}</p>
      <p class="mt-2"><span class="link underline text-base font-normal" @click="showPriceBreakdownModal = true"><font-awesome-icon icon="fa-solid fa-info-circle" /> see price breakdown</span></p>
    </section>
    <!-- Deposit Payment -->
    <section>
      <div v-if="subscription">
        <p class="text-2xl font-semibold select-none cursor-pointer" @click="subscription = false">
          Payment Plan
        </p>
        <p>$100 per month until project price is paid in full</p>
        <p class="mt-2 text-sm">Due now is the first $100 installment payment. The remaining payments will auto charge exactly one month apart to the card provided below.</p>
      </div>
      <div v-else>
        <p class="text-2xl font-semibold select-none cursor-pointer" @click="subscription = true">Deposit Payment</p>
        <p class="text-lg font-semibold mt-5">
          Deposit Amount: {{ depositDollarAmount }} <span class="text-sm font-normal">(USD)</span>
        </p>
        <p class="mt-2 text-sm">Due now is a deposit of half the total project price.</p>
        <p class="text-sm mt-2">A balance payment of
          {{ depositDollarAmount }} will be due within 2 weeks after the FUB revamp is complete.<br /><strong>PLEASE
            NOTE:</strong> The two week window to pay balance <strong>IS NOT CONTINGENT</strong> on a follow up call
          with you after the setup. Two weeks is a hard deadline.</p>
      </div>
      <div class="inline-block bg-gray-100 p-5 rounded pt-3 mt-5">
        <p class="font-semibold">Billing Information</p>
        <VTextField :v="v$.formModel.billing.firstName" placeholder="First Name" />
        <VTextField :v="v$.formModel.billing.lastName" placeholder="Last Name" />
        <VTextField :v="v$.formModel.billing.email" placeholder="Email" />
        <VTextField :v="v$.formModel.billing.addressLine1" placeholder="Address Line 1" />
        <VTextField :v="v$.formModel.billing.addressLine2" placeholder="Address Line 2" />
        <CountrySelect
          v-model="formModel.billing.country"
          :errorMessage="v$.formModel.billing.country.$error ? v$.formModel.billing.country.$errors[0].$message : ''"
        />
        <VTextField :v="v$.formModel.billing.city" placeholder="City" />
        <StateSelect
          v-model="formModel.billing.state"
          :errorMessage="v$.formModel.billing.state.$error ? v$.formModel.billing.state.$errors[0].$message : ''"
          :showProvinces="formModel.billing.country === 'Canada'"
        />
        <VTextField :v="v$.formModel.billing.zip" :placeholder="formModel.billing.country === 'Canada' ? 'Postal Code' : 'Zip Code'" />
        <VTextField :v="v$.formModel.billing.phone" placeholder="Phone" />
        <p class="mt-5 font-semibold">Card Information</p>
        <SquarePayment ref="squarePayment" />
      </div>
      <div>
        <button @click="submit" :disabled="!canSubmit" class="button mt-5">{{ submitText }}</button>
      </div>
      <p v-if="v$.$error" class="mt-2 text-red-500">
        Oops... There is an issue with the form. Check fields for errors.
      </p>
    </section>
    <PriceBreakdownModal
      v-if="showPriceBreakdownModal"
      @close="showPriceBreakdownModal = false"
      :pricing="pricing"
      :pricingMeta="pricingMeta"
    />
    <SubmittingModal
      v-if="isSubmitting"
      @close="(isSubmitting = false)"
      :loading="loading"
      :errorMessage="error"
      :noPayment="true"
    >
      <template #success>
        <div>
          <p class="text-3xl text-site-purple font-semibold">Sign up complete!</p>
          <p class="text-xl pt-5" v-if="subscription">Info was submitted successfully and payment subscription was set up.</p>
          <p class="text-xl pt-5" v-else>Info was submitted successfully and the deposit payment of <strong class="font-semibold">{{depositDollarAmount}}</strong> has been processed.</p>
        </div>
      </template>
    </SubmittingModal>
  </FormPageLayout>
</template>
<script>
import { required, requiredIf, formValidationMixin } from '@/mixins/formValidateMixin'
import AffiliateSelect from '@/components/common/form/AffiliateSelect'
import SalesRepSelect from '../../common/form/SalesRepSelect'
import _pick from 'lodash/pick'
import SpanishContentModal from "../setup-form/SpanishContentModal";
import { SETUP_TYPE } from '../../../constants';
import { getPricing } from '@/pricing'

export default {
  mixins: [formValidationMixin],
  components: {
    SalesRepSelect,
    SpanishContentModal,
    AffiliateSelect
  },
  data() {
    return {
      loading: false,
      error: '',
      isSubmitting: false,
      subscription: false,
      showSpanishContentModal: false,
      showPriceBreakdownModal: false,
      formModel: {
        salesRepresentative: "",
        basicInfo: {
          firstName: "",
          lastName: "",
          email: "",
          phone: ""
        },
        referral: "",
        referredFrom: "",
        affiliate: "",
        lenderAddOn: "",
        spanishActionPlans: "",
        defaultLanguage: "",
        billing: {
          firstName: "",
          lastName: "",
          email: "",
          addressLine1: "",
          addressLine2: "",
          country: "",
          city: "",
          state: "",
          zip: "",
          phone: "",
        },
        discountAmount: "",
        customWorkNeeded: "",
        customWorkAmount: "",
        customWorkNotes: ""
      },
    };
  },
  validations() {
    return {
      formModel: {
        salesRepresentative: { required },
        basicInfo: {
          firstName: { required },
          lastName: { required },
          email: { required },
          phone: { required }
        },
        referral: {},
        referredFrom: {},
        affiliate: {},
        spanishActionPlans: { required },
        defaultLanguage: { required: requiredIf(this.includeSpanishActionPlans) },
        lenderAddOn: { required },
        discountAmount: {},
        customWorkNeeded: { required },
        customWorkAmount: { required: requiredIf(this.customWorkNeeded === 'Flat Rate Work') },
        customWorkNotes: { required: requiredIf(this.customWorkNeeded && this.customWorkNeeded !== 'No') },
        billing: {
          firstName: { required },
          lastName: { required },
          email: { required: requiredIf(this.subscription) },
          addressLine1: { required },
          addressLine2: {},
          country: { required },
          city: { required },
          state: { required },
          zip: { required },
          phone: {}
        },
      },
    };
  },
  computed: {
    setupType() {
      return SETUP_TYPE.SIERRA
    },
    pricing() {
      return getPricing(this.setupType, this.legacyPricing)
    },
    legacyPricing() {
      return this.$route.query.legacyPricing || ''
    },
    pricingMeta() {
      return {
        includeSpanishActionPlans: this.includeSpanishActionPlans,
        includeLenderAddOn: this.includeLenderAddOn,
        customWorkAmount: this.customWorkAmount,
        discountAmount: this.discountAmount,
      }
    },
    totalAmount() {
      let amount = this.pricing.BASE
      if (this.includeSpanishActionPlans) {
        amount += this.pricing.SPANISH_ACTION_PLANS
      }
      if (this.includeLenderAddOn) {
        amount += this.pricing.LENDER_ADD_ON
      }
      if (this.customWorkAmount) {
        amount += this.customWorkAmount
      }
      if (this.discountAmount) {
        amount -= this.discountAmount
      }
      return amount
    },
    customWorkAmount() {
      return Number(this.formModel.customWorkAmount)
    },
    discountAmount() {
      return Number(this.formModel.discountAmount)
    },
    depositAmount() {
      return this.totalAmount/2
    },
    totalDollarAmount() {
      return `$${this.totalAmount.toLocaleString('en')}`
    },
    depositDollarAmount() {
      return `$${this.depositAmount.toLocaleString('en')}`
    },
    includeSpanishActionPlans() {
      return this.formModel.spanishActionPlans === 'Yes'
    },
    canSubmit() {
      return !this.isSubmitting;
    },
    includeLenderAddOn() {
      return this.formModel.lenderAddOn === 'Yes'
    },
    customWorkNeeded() {
      return this.formModel.customWorkNeeded
    },
    discountProvided() {
      return !!this.formModel.discountAmount
    },
    submitText() {
      return this.subscription ? 'Submit and pay first installment of $100' : `Submit and pay ${this.depositDollarAmount} Deposit`
    }
  },
  watch: {
    customWorkNeeded(newVal) {
      if (newVal === 'No') {
        this.formModel.customWorkAmount = "",
        this.formModel.customWorkNotes = ""
      } else if (newVal === 'Hourly Work') {
        this.formModel.customWorkAmount = ""
      }
    }
  },
  methods: {
    buildNotes() {
      return _pick(this.formModel, [
        'spanishActionPlans',
        'defaultLanguage',
        'lenderAddOn',
        'customWorkNeeded',
        'customWorkAmount',
        'customWorkNotes',
        'discountAmount'
      ])
    },
    async submit() {
      if (!(await this.v$.$validate())) return
      
      try {
        this.error = ''
        const notes = this.buildNotes();
        const { locationId, sourceId } = await this.$refs.squarePayment.tokenize()
        const payment = {
          note: 'Sierra Deposit Payment',
          isDeposit: true,
          billing: this.formModel.billing,
          locationId,
          sourceId
        }
        this.loading = true;
        this.isSubmitting = true;

        await this.$service.manualSignUp({
          basicInfo: this.formModel.basicInfo,
          notes,
          referredFrom: this.formModel.referredFrom,
          affiliate: this.formModel.affiliate,
          meta: {
            setupType: this.setupType,
            subscription: this.subscription,
            spanishActionPlans: this.includeSpanishActionPlans,
            includeLenderAddOn: this.includeLenderAddOn,
            customWorkNeeded: this.customWorkNeeded,
            discountProvided: this.discountProvided,
            salesRepresentative: this.formModel.salesRepresentative,
            customWorkAmount: this.customWorkAmount,
            discountAmount: this.discountAmount,
            legacyPricing: this.legacyPricing
          },
          payment
        })

        this.loading = false
      } catch (e) {
        this.loading = false
        this.error = this.$service.getErrorMessage(e)
      }
    },
  },
};
</script>
<style scoped>
section {
  @apply mt-10;
}

label {
  @apply mr-2;
}

.field {
  @apply mt-2;
}

input[type="text"],
input[type="password"],
input[type=number],
select {
  @apply border rounded py-1 px-2 w-64;
}

input[type="radio"] {
  @apply mr-2;
}

.field-block-error {
  @apply inline-block border border-red-500 p-2 rounded;
}
</style>
